const getHotelsTotalSum = (hotels, key = "price") => {
  if (!Array.isArray(hotels)) return 0;

  return hotels.reduce((sum, hotel) => {
    const value = parseFloat(hotel[key]) || 0;
    return sum + value;
  }, 0);
};

export default getHotelsTotalSum;
