import { Box, Modal } from "@mui/material";
import OrderPreview from "./OrderPreview";
import styles from "../style.module.scss";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  px: 4,
  pb: 3,
  borderRadius: "10px",
};

const OrderPopup = ({ isOpen, close, data }) => {
  return (
    <Modal
      open={isOpen}
      onClose={close}
      className={styles.order_popup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.card} style={modalStyle}>
        <div className={styles.header}>
          <h2>{`Order #${data?.external_order_id}`}</h2>
          <CloseOutlinedIcon onClick={() => close()} />
        </div>
        <OrderPreview orderId={data} />
      </Box>
    </Modal>
  );
};

export default OrderPopup;
