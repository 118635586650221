import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import styles from "./style.module.scss";
import moment from "moment";
import useChats from "../../../../hooks/api/useChatsAPI";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { GlassIcon } from "../../../../assets/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import { stringSingleAvatar } from "../../../../utils";
import ForwardSearchResult from "../ForwardSearchResult";
import ForwardConfirmModal from "../ForwardConfirmModal";

export default function ForwardModal({ open, onClose, messageId }) {
  const { id } = useParams();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [payload, setPayload] = useState({});
  const [search, setSearch] = useState(null);
  const [debouncedSearch] = useDebounce(search, 500);

  const {
    contacts,
    contactLoading,
    forwardData,
    forwardFetchNextPage,
    forwardHasNextPage,
    forwardIsFetching,
  } = useChats({
    search: debouncedSearch,
  });

  const handleForwardMessage = (data) => {
    setConfirmIsOpen(true);
    setPayload({
      from_id: Number(id),
      to_id: data?.chat_id,
      to_access_hash: data?.hash,
      message_ids: [messageId],
    });
  };

  const chats = useMemo(() => {
    if (forwardData) {
      const groupedChats = {};

      forwardData?.pages?.forEach((page) => {
        page?.payload?.chats?.forEach((item) => {
          const formattedDate = moment(item?.LastMessage?.Date * 1000).format(
            "MMMM DD, YYYY"
          );

          const chatItem = {
            name:
              item.Chat.Title || `${item.Chat.LastName} ${item.Chat.FirstName}`,
            last_name: item.Chat.LastName || item.Chat.Title,
            first_name: item.Chat.FirstName || item.Chat.Title,
            platform: "telegram.com",
            avatar: item?.Agent.Photo || "/chat_ava.jpg",
            assigned: item?.IsAssigned && item?.Agent?.LastName,
            deleted: item?.Chat?.Deleted,
            id: `${item?.Agent.Id}-${item?.Chat.ID}`,
            hash: item?.Chat.AccessHashStr || item?.Chat.ID,
            chat_id: item?.Chat.ID,
            assigned_agent: `${item?.Agent?.LastName} ${item?.Agent?.FirstName}`,
          };

          if (!groupedChats[formattedDate]) {
            groupedChats[formattedDate] = [];
          }

          groupedChats[formattedDate].push(chatItem);
        });
      });

      return Object.keys(groupedChats)
        .sort((a, b) =>
          moment(b, "MMMM DD, YYYY").diff(moment(a, "MMMM DD, YYYY"))
        )
        .map((date) => ({
          date: date,
          users: groupedChats[date],
        }));
    }

    return [];
  }, [forwardData]);

  const formattedContacts = useMemo(() => {
    if (contacts?.payload?.response) {
      return contacts?.payload?.response?.map((item) => {
        return {
          name: `${item?.LastName} ${item?.FirstName}`,
          username: item?.Username,
          platform: "telegram.com",
          avatar: item?.Photo || "/chat_ava.jpg",
          id: `${item?.Agent?.Id}-${item?.ID}`,
          hash: item?.AccessHashStr,
          last_message: item?.LastMessage?.Message?.replace(/\n/g, " ") || "",
          unread: item?.Dialog?.UnreadCount || 0,
          assigned_agent: item?.Agent
            ? `${item?.Agent.LastName} ${item?.Agent.FirstName}`
            : null,
          assigned: item?.IsAssigned,
          chat_id: item?.ID,
        };
      });
    }

    return [];
  }, [contacts]);

  const groups =
    forwardData?.pages?.flatMap((page) => page?.payload?.chats || []) || [];

  const compudetChats = formattedContacts?.length ? formattedContacts : chats;

  const handleClearSearch = () => {
    setSearch("");
  };

  const handleClose = () => {
    handleClearSearch();
    onClose();
    setPayload({});
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <Box className={styles.box}>
        <div className={styles.header}>
          <div className={styles.head_content}>
            <h3>Forward</h3>
          </div>
          <CloseIcon onClick={handleClose} className={styles.close} />
        </div>
        <Box className={styles.content}>
          <div className={styles.search}>
            <TextField
              style={{
                background: "#fff",
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {contactLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      <GlassIcon />
                    )}
                  </InputAdornment>
                ),
                endAdornment: formattedContacts?.length ? (
                  <InputAdornment>
                    <Box
                      className={styles.close_icon}
                      onClick={handleClearSearch}
                    >
                      <CloseIcon />
                    </Box>
                  </InputAdornment>
                ) : null,
              }}
            />
          </div>

          {formattedContacts?.length ? (
            <ForwardSearchResult
              formattedContacts={formattedContacts}
              handleForwardMessage={handleForwardMessage}
            />
          ) : (
            <div id="scrollableDiv" style={{ overflow: "auto" }}>
              <InfiniteScroll
                dataLength={groups?.length}
                next={forwardFetchNextPage}
                hasMore={forwardHasNextPage}
                style={{ overflow: "auto" }}
                height={300}
                scrollableTarget="scrollableDiv"
                loader={
                  forwardIsFetching && (
                    <Box className={styles.loader}>
                      <CircularProgress />
                    </Box>
                  )
                }
              >
                {compudetChats?.map((group) => (
                  <div key={group.date} className={styles.date_group}>
                    <div className={styles.group_box}>
                      {group.users.map((user, userIndex) => {
                        return (
                          <Box
                            key={userIndex}
                            className={classNames(styles.user)}
                            onClick={() => {
                              handleForwardMessage(user);
                            }}
                          >
                            <div className={styles.userInfo}>
                              <Avatar
                                style={{
                                  width: "40px",
                                  height: "40px",
                                }}
                                {...stringSingleAvatar(
                                  [user?.last_name || user?.first_name].join(
                                    " "
                                  )
                                )}
                              />
                              <div className={styles.details}>
                                <div className={styles.userName}>
                                  {user?.deleted
                                    ? "Deleted account"
                                    : user.name}
                                </div>
                              </div>
                            </div>
                          </Box>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            </div>
          )}
        </Box>
      </Box>
      <ForwardConfirmModal
        open={confirmIsOpen}
        setOpen={setConfirmIsOpen}
        payload={payload}
        onClose={handleClose}
      />
    </Dialog>
  );
}
