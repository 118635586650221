import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import styles from "./style.module.scss";
import useUploadImage from "../../../../hooks/api/useUploadImage";
import AddIcon from "@mui/icons-material/Add";
import useHotelsAPI from "../../../../hooks/api/useHotelsAPI";
import { DeleteImageIcon } from "../../../../assets/icons";
import FormReactSelect from "../../../../components/FormSelect";
import useCountry from "../../../../hooks/api/useCountryAPI";
import useRegion from "../../../../hooks/api/useRegionAPI";

const HotelCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  let dragCounter = 0;
  const dropRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);

  const [uploadedImages, setUploadedImages] = useState([]);

  const handleHotelSuccess = (res) => {
    setUploadedImages(res.hotel.images);
  };

  const { hotel, createHotel, updateHotel } = useHotelsAPI({
    hotelId: id,
    handleHotelSuccess,
  });
  const { control, handleSubmit, watch } = useForm({
    values: {
      ...(hotel?.hotel || {}),
      country_id: hotel?.hotel?.region?.country?.id,
      region_id: hotel?.hotel?.region?.id,
    },
  });

  const countryId = useWatch({
    control,
    name: "country_id",
  });
  const { uploadMutation, getImage } = useUploadImage();

  const { data: countries } = useCountry({
    enabled: true,
  });

  const countryOptions = useMemo(() => {
    return countries?.countries?.rows?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  }, [countries]);

  const { filteredRegion } = useRegion({
    country_id: countryId,
  });

  const regionOptions = useMemo(() => {
    return filteredRegion?.regions?.rows?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  }, [filteredRegion]);

  const handleDeleteImage = (image) => {
    setUploadedImages((prev) => prev.filter((item, index) => item !== image));
  };

  const onSubmit = (vals) => {
    if (!!id) {
      updateHotel.mutate(
        {
          ...vals,
          images: uploadedImages,
        },
        {
          onSuccess: () => {
            toast.success("Hotel is edited successfully!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Hotel!");
          },
        }
      );
    } else {
      createHotel.mutate(
        {
          ...vals,
          images: uploadedImages,
        },
        {
          onSuccess: () => {
            toast.success("Hotel is added successfully!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Hotel!");
          },
        }
      );
    }
  };

  const renderButtonContent = () => {
    if (uploadMutation?.isLoading) {
      return <CircularProgress size={36} />;
    }

    if (isDragging) {
      return "Drag and drop files here";
    }

    return (
      <>
        <AddIcon />
        Click to upload
      </>
    );
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    onFileAdd(e.dataTransfer.files, e);
  };

  const onFileAdd = async (files, e) => {
    if (!files || !files.length) return;

    for (let file of files) {
      await new Promise((resolve, reject) => {
        uploadMutation.mutate(file, {
          onSuccess: async (res) => {
            try {
              const resp = await getImage(res.$id);
              setUploadedImages((prev) => [...prev, resp.href]);
              resolve();
            } catch (error) {
              toast.error("Error fetching the uploaded image");
              reject(error);
            }
          },
          onError: (err) => {
            toast.error("Error uploading the image!");
            reject(err);
          },
        });
      });
    }
    e.target.value = null;
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter++;
    if (dragCounter === 1) {
      setIsDragging(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter--;
    if (dragCounter === 0) {
      setIsDragging(false);
    }
  };

  useEffect(() => {
    const dropArea = dropRef.current;
    if (dropArea) {
      dropArea.addEventListener("dragenter", handleDragEnter);
      dropArea.addEventListener("dragleave", handleDragLeave);
      dropArea.addEventListener("dragover", (e) => e.preventDefault());
      dropArea.addEventListener("drop", handleDrop);
    } else {
      dropArea.removeEventListener("dragleave", handleDragLeave);
    }

    return () => {
      if (dropArea) {
        dropArea.removeEventListener("dragenter", handleDragEnter);
        dropArea.removeEventListener("drop", handleDrop);
        dropArea.removeEventListener("dragleave", handleDragLeave);
      } else {
      }
    };
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Wrapper>
        <div>
          <Content title={!!id ? "Edit Hotel" : "Add Hotel"} height="80vh">
            <Box width="100%" display="flex" gap="20px">
              <Box width="100%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Hotel Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Hotel name"
                        className={styles.input}
                        required
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Hotel Address</p>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className={styles.input}
                        required
                        placeholder="Hotel address"
                      />
                    )}
                  />
                </Box>
              </Box>
            </Box>
            <Box width="100%" display="flex" gap="20px" marginTop="24px">
              <Box className={styles.field}>
                <p className={styles.label}>Country</p>
                <FormReactSelect
                  name="country_id"
                  placeholder="Select country"
                  control={control}
                  options={countryOptions}
                  required
                />
              </Box>
              <Box className={styles.field}>
                <p className={styles.label}>Region</p>
                <FormReactSelect
                  name="region_id"
                  placeholder="Select region"
                  control={control}
                  options={regionOptions}
                  required
                />
              </Box>
            </Box>
            <Box width="50%" display="flex" gap="20px" marginTop="24px">
              <Box className={styles.field} width="100%">
                <p className={styles.label}>Hotel Category</p>
                <Controller
                  name="category"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      placeholder="Hotel category"
                      className={styles.input}
                      required
                    />
                  )}
                />
              </Box>
            </Box>

            <Box className={styles.field} marginTop={"20px"} width="100%">
              <p className={styles.label}>Upload image</p>
              <Box className={styles.img_section} ref={dropRef}>
                <Button
                  variant="contained"
                  component="label"
                  className={styles.upload}
                >
                  {renderButtonContent()}
                  <input
                    type="file"
                    multiple
                    hidden
                    onChange={(e) => onFileAdd(e.target.files, e)}
                  />
                </Button>
                {uploadedImages.map((image, index) => (
                  <Box className={styles.image}>
                    <Box
                      key={image}
                      component="img"
                      src={image}
                      alt={`Uploaded ${index + 1}`}
                    />
                    <Box
                      className={styles.delete}
                      onClick={() => handleDeleteImage(image)}
                    >
                      <DeleteImageIcon />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Content>
        </div>

        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createHotel.isLoading}
            style={{ width: "120px" }}
          >
            {createHotel.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default HotelCreate;
