import React from "react";
import { useWatch, Controller } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import styles from "./style.module.scss";

import { promiseAirline } from "../../../../../utils/getAirlines";
import {
  carrierCodes,
  flightTypes,
  flightTypesOption,
  iataCodes,
} from "../../../../../consts/flights";

import MultipleFlightFormType from "./SegmentForm/MultipleFormType";
import OneWayFormType from "./SegmentForm/OneWayFormType";
import RoundTripFormType from "./SegmentForm/RoundTripFormType";
import FormReactSelect from "../../../../../components/FormSelect";
import FormReactAsyncSelect from "../../../../../components/FormSelect/AsyncSelect";
import HFDateTimePicker from "../../../../../components/FormDatePicker/DateTimePicker";
import { promiseCity } from "../../../../../utils/getCities";

const Renderers = {
  [flightTypes.ONE_WAY]: OneWayFormType,
  [flightTypes.ROUND_TRIP]: RoundTripFormType,
  [flightTypes.DIFFICULT_ROUTE]: MultipleFlightFormType,
};

const cabinOptions = [
  { value: "ECONOMY", label: "Economy" },
  { value: "PREMIUM_ECONOMY", label: "Premium Economy" },
  { value: "BUSINESS", label: "Business" },
  { value: "FIRST", label: "First" },
];

const coverageOptions = [
  { value: "MOST_SEGMENTS", label: "MOST_SEGMENTS" },
  { value: "AT_LEAST_ONE_SEGMENT", label: "AT_LEAST_ONE_SEGMENT" },
];

const fareTypeOptions = [
  { value: "PUBLISHED", label: "PUBLISHED" },
  { value: "NEGOTIATED", label: "NEGOTIATED" },
  { value: "PRIVATE", label: "PRIVATE" },
];

export default function FlightSegments({
  segments,
  segmentAppend,
  segmentRemove,
  control,
  watch,
  addSegmentParams,
  params,
  addRouteParam,
  changeFlightParams,
  removeRouteParam,
  reset,
  searchTicketsWithSegmentParams,
  changeFlightType,
  setValue,
  setIncludedCarriers,
  unregister,
}) {
  const formSegments = useWatch({ control, name: "segments" });

  const newSegment = { type: flightTypes.ONE_WAY };

  const handleChange = (idx) => {
    unregister(`segments.${idx}.city_from`);
    unregister(`segments.${idx}.city_to`);
    unregister(`segments.${idx}.date_departure`);
    setValue(`segments.${idx}.date_departure`, new Date());
  };

  const addSegmentParamsAndSearchTickets = (index) => {
    addSegmentParams([formSegments[index]]);
    const currentType = watch(`segments.${index}.type`);
    if (currentType === flightTypes.DIFFICULT_ROUTE) {
      searchTicketsWithSegmentParams(formSegments[index]?.routes);
    } else {
      searchTicketsWithSegmentParams([formSegments[index]]);
    }
  };

  return (
    <div className={styles.segment}>
      {!!segments?.length && (
        <Box className={styles.accordion_list}>
          {segments.map((item, idx) => {
            const Renderer = Renderers[watch(`segments.${idx}.type`)] || null;

            return (
              <Accordion className={styles.accordion} key={idx}>
                <AccordionSummary
                  className={styles.summary}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${idx}-content`}
                  id={`panel${idx}-header`}
                >
                  <p>Segment {idx + 1}</p>
                </AccordionSummary>
                <AccordionDetails className={styles.content}>
                  <Box className={styles.segment_form}>
                    <Box className={styles.head}>
                      <FormReactSelect
                        name={`segments.${idx}.type`}
                        control={control}
                        options={flightTypesOption}
                        placeholder="Select flight type"
                        isSearchable
                        customOnChange={(e) => {
                          if (e?.value === flightTypes.DIFFICULT_ROUTE) {
                            handleChange(idx);
                          }
                          changeFlightType(e?.value);
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={() => addSegmentParamsAndSearchTickets(idx)}
                      >
                        <SearchIcon />
                      </Button>
                    </Box>

                    <FormReactAsyncSelect
                      name={`segments.${idx}.carriers`}
                      control={control}
                      isMulti
                      placeholder="Carriers"
                      defaultOptions={carrierCodes}
                      options={carrierCodes}
                      getOptionLabel={(opt) => opt.value}
                      getOptionValue={(opt) => opt.key}
                      loadOptions={promiseAirline}
                      components={{ DropdownIndicator: null }}
                      customOnChange={(val) => {
                        setValue(`segments.${idx}.carriers`, [...val]);
                        setIncludedCarriers(val);
                      }}
                    />

                    {/* <Box>
                      <FormReactSelect
                        name={`segments.${idx}.cabin`}
                        control={control}
                        options={cabinOptions}
                        placeholder="Select cabin class"
                        isSearchable
                        defaultValue={{ value: "ECONOMY", label: "Economy" }}
                      />
                    </Box> */}

                    {/* <Box>
                      <FormReactSelect
                        name={`segments.${idx}.coverage`}
                        control={control}
                        options={coverageOptions}
                        placeholder="Coverage"
                        isSearchable={false}
                        defaultValue={{
                          value: "MOST_SEGMENTS",
                          label: "MOST_SEGMENTS",
                        }}
                      />
                    </Box> */}

                    {/* <Box>
                      <Controller
                        name={`segments.${idx}.maxConnections`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="number"
                            placeholder="Max Connections"
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              background: "#fff",
                            }}
                          />
                        )}
                      />
                    </Box>

                    <Box>
                      <Controller
                        name={`segments.${idx}.includedCheckedBagsOnly`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <FormControlLabel
                            style={{
                              margin: 0,
                            }}
                            label="Included Checked Bags Only"
                            control={
                              <Checkbox
                                checked={!!field.value}
                                onChange={(e) =>
                                  field.onChange(e.target.checked)
                                }
                              />
                            }
                          />
                        )}
                      />
                    </Box> */}

                    {/* <Box>
                      <Controller
                        name={`segments.${idx}.excludedDepartureAirportCodes`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Excluded Departure Airports (comma separated)"
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              background: "#fff",
                            }}
                          />
                        )}
                      />
                    </Box> */}
                    {/* <FormReactAsyncSelect
                      name={`segments.${idx}.excludedDepartureAirportCodes`}
                      control={control}
                      isMulti
                      placeholder="Departure airports"
                      defaultOptions={iataCodes}
                      options={iataCodes}
                      getOptionLabel={(opt) =>
                        opt.key || opt.label || opt.value
                      }
                      getOptionValue={(opt) => opt.value}
                      loadOptions={promiseCity}
                      components={{ DropdownIndicator: null }}
                    /> */}

                    {/* <Box>
                      <Controller
                        name={`segments.${idx}.excludedArrivalAirportCodes`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Excluded Arrival Airports (comma separated)"
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              background: "#fff",
                            }}
                          />
                        )}
                      />
                    </Box> */}

                    {/* <FormReactAsyncSelect
                      name={`segments.${idx}.excludedArrivalAirportCodes`}
                      control={control}
                      isMulti
                      placeholder="Arrival airports"
                      defaultOptions={iataCodes}
                      options={iataCodes}
                      getOptionLabel={(opt) =>
                        opt.key || opt.label || opt.value
                      }
                      getOptionValue={(opt) => opt.value}
                      loadOptions={promiseCity}
                      components={{ DropdownIndicator: null }}
                    />

                    <Box>
                      <Controller
                        name={`segments.${idx}.maxTransferDuration`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Max Transfer Duration"
                            variant="outlined"
                            type="number"
                            size="small"
                            fullWidth
                            style={{
                              background: "#fff",
                            }}
                          />
                        )}
                      />
                    </Box>

                    <Box>
                      <FormReactSelect
                        name={`segments.${idx}.fareType`}
                        control={control}
                        options={fareTypeOptions}
                        placeholder="Select fare type"
                        isSearchable={false}
                        defaultValue={{
                          value: "PUBLISHED",
                          label: "PUBLISHED",
                        }}
                      />
                    </Box>

                    <Box>
                      <Controller
                        name={`segments.${idx}.maxFlightDuration`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Max Flight Duration"
                            variant="outlined"
                            type="number"
                            size="small"
                            fullWidth
                            style={{
                              background: "#fff",
                            }}
                          />
                        )}
                      />
                    </Box>

                    <Box>
                      <Controller
                        name={`segments.${idx}.includedAlliances`}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            placeholder="Included Alliances (comma separated: ONEWORLD, SKYTEAM)"
                            variant="outlined"
                            size="small"
                            fullWidth
                            style={{
                              background: "#fff",
                            }}
                          />
                        )}
                      />
                    </Box> */}

                    {Renderer && (
                      <Renderer
                        control={control}
                        index={idx}
                        params={params}
                        addRouteParam={addRouteParam}
                        changeFlightParams={changeFlightParams}
                        removeRouteParam={removeRouteParam}
                        reset={reset}
                        setValue={setValue}
                        watch={watch}
                      />
                    )}

                    <p
                      className={styles.delete_button}
                      onClick={() => segmentRemove(idx)}
                    >
                      <DeleteOutlineIcon /> Delete
                    </p>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      )}

      <Button
        variant="contained"
        fullWidth
        className={styles.add_segment_button}
        onClick={() => segmentAppend(newSegment)}
      >
        <AddIcon /> Segment
      </Button>
    </div>
  );
}
