import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";
import { useWatch } from "react-hook-form";

export default function PoliciesModal({ open, onClose, control, policies }) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <Box className={styles.box}>
        <div className={styles.header}>
          <div className={styles.content}>
            <h3>Policies</h3>
          </div>
          <CloseIcon onClick={handleClose} className={styles.close} />
        </div>
        <Box className={styles.text_area}>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html:
                policies?.replace(/\n/g, "<br>") || "Please add policies.",
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
}
