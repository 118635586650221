import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { RoomBedIcon } from "../../../../../../../assets/icons";
import useRoomOptionAPI from "../../../../../../../hooks/api/useRoomOptionAPI";
import styles from "./styles.module.scss";

export default function RoomOptionModal({
  open,
  onClose,
  onSave,
  initialSelectedIds,
  openIndex,
}) {
  const [selected, setSelected] = useState([]);

  const { data } = useRoomOptionAPI({
    enabled: true,
    page: 0,
    room_type_id: openIndex,
  });

  const roomOptions = useMemo(() => {
    return (
      data?.roomOptions?.rows?.map((item) => ({
        label: item?.name,
        value: item?.id,
        icon: item?.icon,
      })) || []
    );
  }, [data]);

  useEffect(() => {
    if (open) {
      setSelected(initialSelectedIds || []);
    }
  }, [open, initialSelectedIds]);

  const handleToggleOption = (optionId) => {
    setSelected((prev) => {
      if (prev.includes(optionId)) {
        return prev.filter((id) => id !== optionId);
      } else {
        return [...prev, optionId];
      }
    });
  };

  const handleToggleAll = () => {
    if (selected.length === roomOptions.length) {
      setSelected([]);
    } else {
      setSelected(roomOptions.map((option) => option.value));
    }
  };

  const handleSave = () => {
    onSave(selected, openIndex);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <Box className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <div className={styles.header}>
          <div className={styles.add_user}>
            <RoomBedIcon />
          </div>
          <div className={styles.content}>
            <h3>Name of room</h3>
            <p>Choose room options</p>
          </div>
        </div>
        <FormGroup className={styles.list}>
          <FormControlLabel
            style={{ columnGap: "12px" }}
            control={
              <Checkbox
                className={styles.checkbox}
                sx={{
                  "&.Mui-checked": {
                    color: "#079455",
                  },
                }}
                checked={
                  selected.length === roomOptions.length &&
                  roomOptions.length > 0
                }
                indeterminate={
                  selected.length > 0 && selected.length < roomOptions.length
                }
                onChange={handleToggleAll}
              />
            }
            label="All"
          />
          {roomOptions.map((option) => (
            <FormControlLabel
              style={{ columnGap: "12px" }}
              key={option.value}
              control={
                <Checkbox
                  className={styles.checkbox}
                  sx={{
                    "&.Mui-checked": {
                      color: "#079455",
                    },
                  }}
                  checked={selected.includes(option.value)}
                  onChange={() => handleToggleOption(option.value)}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
        <div className={styles.footer}>
          <Button className={styles.cancel_button} onClick={handleClose}>
            Cancel
          </Button>
          <Button className={styles.save_button} onClick={handleSave}>
            Save
          </Button>
        </div>
      </Box>
    </Dialog>
  );
}
