import FlightInfo from "./FlightInfo";
import styles from "./style.module.scss";
import ContactInformation from "./ContactInformation";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";

import { Box, CircularProgress, Container } from "@mui/material";
import HotelInfo from "../HotelInfo";
import TransportSection from "../TransportSection";
import InsuranceSection from "../InsuranceSection";
import Sponsors from "../Sponsors";
import useWindowWidth from "../../../../utils/useWindow";
import PaymentInfo from "./PaymentInfo";
import Customers from "./Customers";
import useOrderAPI from "../../../../hooks/api/useOrderAPI";

const OrderPreview = ({ orderId }) => {
  const windowWidth = useWindowWidth();
  const [comments, setComments] = useState([]);
  const [confirmTip, setConfirmTip] = useState(false);

  const { order, isLoadingCurrentOrder } = useOrderAPI({
    orderId: orderId?.id,
  });

  const { setValue, control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      with_hotel: order?.with_hotel,
      with_transport: order?.with_transport,
      has_insurance: true,
      agent_tip: order?.agent_tip,
      is_rent_car: false,
      is_airport_transfer: false,
      orderHotels: order?.orderHotels,
      contact_number: null,
      contact_email: null,
      insurance_price: 0,
      image: order?.user?.image,
    },
  });

  useEffect(() => {
    if (!order) return;

    reset({
      with_hotel: order.with_hotel,
      with_transport: order.with_transport,
      has_insurance: order.has_insurance,
      orderHotels: order?.orderHotels,
      agent_tip: order?.agent_tip,
      image: order?.user?.image,
    });

    setComments(
      order.customers.map((customer) => ({
        customer_id: customer.id,
        comment: null,
      }))
    );
  }, [order]);

  const onChangeComment = (e, idx) => {
    const _comments = [...comments];

    _comments[idx].comment = e.target.value;

    setComments(_comments);
  };

  if (isLoadingCurrentOrder)
    return (
      <Box
        width="100%"
        display="flex"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#fff"
      >
        <CircularProgress size={60} />
      </Box>
    );

  return (
    <>
      <main className={styles.main}>
        <form>
          <Container className={styles.container}>
            <div className={styles.box}>
              <HotelInfo control={control} />
              <FlightInfo user={order?.user} flights={order?.flights} />
              <Customers
                customers={order?.customers}
                comments={comments}
                onChangeComment={onChangeComment}
                customerPayments={order?.customer_payments}
              />
              <TransportSection
                watch={watch}
                setValue={setValue}
                control={control}
              />

              <ContactInformation
                phone_number={order?.contact_number}
                email={order?.contact_email}
                control={control}
              />
              <InsuranceSection
                setValue={setValue}
                confirmTip={confirmTip}
                setConfirmTip={setConfirmTip}
                control={control}
                tip={order?.agent_tip}
              />
              {windowWidth > 992 && <Sponsors />}
            </div>
            <div className={styles.payment}>
              <PaymentInfo
                isConfirmed={order?.is_confirmed}
                confirmTip={confirmTip}
                control={control}
                tip={order?.agent_tip}
                payment={order?.payment}
                insurance_price={watch().insurance_price}
                customerPayments={
                  order?.customer_payments
                    ? JSON.parse(order?.customer_payments)
                    : []
                }
                passengerPaymentInfo={order?.passengers_payment_info}
                externalOrderId={order?.external_order_id}
              />
            </div>
            {windowWidth < 992 && <Sponsors />}
          </Container>
        </form>
      </main>
    </>
  );
};

export default OrderPreview;
