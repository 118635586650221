import React from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  CardActions,
  Button,
} from "@mui/material";
import styles from "./style.module.scss";
import DomainIcon from "@mui/icons-material/Domain";
import { useDispatch } from "react-redux";
import { removeSelectedHotel } from "../../../../../../../redux/hotel/hotelSlice";
import { useNavigate } from "react-router-dom";
const HotelCard = ({
  name,
  check_in,
  check_out,
  roomType,
  adults,
  children,
  data,
  existedOrderId,
  infant,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleHotelSelect = () => {
    dispatch(removeSelectedHotel(data.unique_id));
  };

  const handleRedirect = () => {
    if (existedOrderId) {
      navigate(`/orders/${existedOrderId}/hotels/${data.unique_id}`);
    } else {
      navigate(`/orders/create/hotels/${data.unique_id}`);
    }
  };

  return (
    <Card className={styles.card}>
      <CardContent className={styles.cardContent}>
        <Box display="flex" alignItems="center" className={styles.topRow}>
          <Box className={styles.iconWrapper}>
            <DomainIcon size={24} color="#344054" />
          </Box>
          <Box ml={2}>
            <Typography className={styles.hotelLabel}>Hotel</Typography>
            <Typography className={styles.hotelName}>{name}</Typography>
          </Box>
        </Box>

        <Divider className={styles.divider} />

        <Box display="flex" className={styles.infoRow}>
          <Box className={styles.infoColumn}>
            <Typography className={styles.label}>Stay Dates</Typography>
            <Typography className={styles.value}>
              {check_in} - {check_out}
            </Typography>
          </Box>
          <Box className={styles.infoColumn}>
            <Typography className={styles.label}>Room Type</Typography>
            <Typography className={styles.value}>{roomType}</Typography>
          </Box>
          <Box className={styles.infoColumn}>
            <Typography className={styles.label}>Number of Guests</Typography>
            <Typography className={styles.value}>
              {adults > 0 ? `Adults: ${adults}` : ""}
              <br />
              {children > 0 ? `Children: ${children}` : ""}

              <br />
              {infant > 0 ? `Infants: ${infant}` : ""}
            </Typography>
          </Box>
        </Box>
      </CardContent>

      <CardActions className={styles.actions}>
        <Button
          onClick={handleHotelSelect}
          variant="contained"
          fullWidth
          className={styles.delete_button}
        >
          Delete
        </Button>
        <Button
          onClick={handleRedirect}
          variant="outlined"
          fullWidth
          className={styles.edit_button}
        >
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};

export default HotelCard;
