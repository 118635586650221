import { Dialog } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import useChats from "../../../../hooks/api/useChatsAPI";

export default function ForwardConfirmModal({
  open,
  setOpen,
  payload,
  onClose,
}) {
  const { telegramForward } = useChats();

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    handleClose();
    onClose();

    toast.promise(telegramForward.mutateAsync(payload), {
      loading: "Forwarding...",
      success: () => {
        return "Success";
      },
      error: (err) => {
        if (err?.data?.message === "user already assigned")
          return "This user is already assigned to another agent";
        return "Error";
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={handleClose} className={styles.close} />
        <p>Are you sure you want to forward current message?</p>
        <div className={styles.buttons}>
          <button onClick={handleClose}>No</button>
          <button onClick={handleSubmit}>Yes</button>
        </div>
      </div>
    </Dialog>
  );
}
