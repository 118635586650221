import { Box, Button, CircularProgress, TextField } from "@mui/material";
import Content from "../../../components/Content";
import Wrapper from "../../../components/Wrapper";
import styles from "./style.module.scss";
import TSelect from "../../../components/Select";
import { userRoles, userRolesForAdmin } from "../../../consts/roles";
import { useEffect, useState } from "react";
import useUsers from "../../../hooks/api/useUsersAPI";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";
import { isUserSupervisor } from "../../../utils";
import { useSelector } from "react-redux";
import useUploadImage from "../../../hooks/api/useUploadImage";
import { makeCDN } from "../../../utils/uploadImage";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const UserCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const currentUser = useSelector((store) => store.auth.user);
  const { uploadMutation, getImage } = useUploadImage();

  const { user, createUser, updateUser } = useUsers({ userId: id });
  const { control, handleSubmit } = useForm({
    values: { ...(user?.user || {}), password: null },
  });
  useEffect(() => {
    if (!user) return;
    setImage(user.user?.image);
    setRole(user.user?.role);
  }, [user]);

  const [role, setRole] = useState("");

  const onChangeSelect = (value) => setRole(value);

  const onClick = (vals) => {
    if (!role) return toast.error("Select a role to new user");

    if (!!id) {
      updateUser.mutate(
        {
          ...vals,
          role,
          new_password: vals.new_password || null,
          image: image,
        },
        {
          onSuccess: () => {
            toast.success("User is edited successful!");
            navigate("/users");
          },
          onError: () => {
            toast.error("Error in editing user!");
          },
        }
      );
    } else
      createUser.mutate(
        { ...vals, role, image: image },
        {
          onSuccess: () => {
            toast.success("User is added successful!");
            navigate("/users");
          },
          onError: () => {
            toast.error("Error in adding user!");
          },
        }
      );
  };

  const onFileAdd = async (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    uploadMutation.mutate(e.target.files[0], {
      onSuccess: async (res) => {
        const resp = await getImage(res.$id);
        setImage(resp.href);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content title={!!id ? "Edit user" : "Add user"}>
            <Box
              display="flex"
              flexDirection="row"
              gap="12px"
              marginTop="24px"
              flexWrap="wrap"
            >
              <Box className={styles.field}>
                <p className={styles.label}>First name</p>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} className={styles.input} required />
                  )}
                />
              </Box>
              <Box className={styles.field}>
                <p className={styles.label}>Last name</p>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} className={styles.input} required />
                  )}
                />
              </Box>
              {/* <Box className={styles.field}>
                <p className={styles.label}>Second name</p>
                <Controller
                  name="second_name"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} className={styles.input} required />
                  )}
                />
              </Box> */}
              <Box className={styles.field}>
                <p className={styles.label}>Phone number</p>
                <Controller
                  name="phone_number"
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} className={styles.input} required />
                  )}
                />
              </Box>
              <Box className={styles.field}>
                <p className={styles.label}>Email</p>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      type="email"
                      {...field}
                      className={styles.input}
                      required
                    />
                  )}
                />
              </Box>
              <Box className={styles.field}>
                <p className={styles.label}>Role</p>
                <TSelect
                  placeholder=""
                  value={userRoles.find((rl) => rl.value === role)}
                  loadOptions={() =>
                    isUserSupervisor(currentUser?.role)
                      ? userRoles
                      : userRolesForAdmin
                  }
                  defaultOptions={
                    isUserSupervisor(currentUser?.role)
                      ? userRoles
                      : userRolesForAdmin
                  }
                  components={{ IndicatorSeparator: null }}
                  onChange={(e) => onChangeSelect(e.value)}
                  getOptionLabel={(opt) => opt.label}
                  getOptionValue={(opt) => opt.value}
                  styles={{
                    control: () => ({
                      backgroundColor: "#fff",
                      minWidth: "280px",
                      border: "1px solid rgba(229, 233, 235, 1)",
                    }),
                  }}
                />
              </Box>
              <Box className={styles.field}>
                <p className={styles.label}>
                  {!!id ? "New password" : "Password"}
                </p>
                <Controller
                  name={!!id ? "new_password" : "password"}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className={styles.input}
                      required={!id}
                    />
                  )}
                />
              </Box>
              <Box className={styles.field}>
                <p className={styles.label}>User image</p>
                <label
                  htmlFor="imageUpload"
                  style={{
                    width: "243px",
                    height: "243px",
                    border: "2px dashed #c1c1c1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  {uploadMutation.isLoading ? (
                    <CircularProgress size={30} />
                  ) : image ? (
                    <img
                      src={makeCDN(image)}
                      width="100%"
                      height="100%"
                      alt="Upload"
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <AddAPhotoIcon />
                  )}

                  <input
                    type="file"
                    id="imageUpload"
                    onChange={onFileAdd}
                    style={{
                      display: "none",
                    }}
                  />
                </label>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createUser.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createUser.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default UserCreate;
