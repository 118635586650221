import { Avatar, Box } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { RightPanelIcon } from "../../../../assets/icons";
import { setOpenProfile } from "../../../../redux/chat/chatSlice";
import { stringSingleAvatar } from "../../../../utils";
import ActionRenderer from "../ActionRenderer";
import ContactUpdateModal from "../Contact/Update";
import styles from "./styles.module.scss";
import { ExpandMoreOutlined } from "@mui/icons-material";

const PinnedMessagesPagination = ({ pinMessages, currentPinnedIndex }) => {
  if (pinMessages?.length < 2) return;
  return (
    <div className={styles.pagination}>
      {pinMessages.map((_, index) => (
        <div
          key={index}
          className={`${styles.dot} ${
            index === currentPinnedIndex ? styles.active : ""
          }`}
        />
      ))}
    </div>
  );
};

export const FieldHeader = ({ pinMessages, setIsPinField, chatType }) => {
  const dispatch = useDispatch();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [currentPinnedIndex, setCurrentPinnedIndex] = useState(0);

  const { openProfile } = useSelector((store) => store.chat);

  const { chatData } = useOutletContext();

  const onOpenUpdateContactModal = () => {
    setOpenUpdateModal(true);
  };

  const cyclePinnedMessage = () => {
    if (!pinMessages || pinMessages.length === 0) return;
    setCurrentPinnedIndex((prevIndex) => (prevIndex + 1) % pinMessages.length);
  };

  const scrollToMessage = (messageId) => {
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      messageElement.classList.add("highlighted");

      messageElement.scrollIntoView({ behavior: "smooth", block: "center" });

      setTimeout(() => {
        messageElement.classList.remove("highlighted");
      }, 1000);
    }
  };
  return (
    <>
      <div className={styles.header}>
        <Box className={styles.content}>
          <div className={styles.img_box}>
            <Avatar
              {...stringSingleAvatar(
                [
                  chatData?.Users?.[0]?.LastName ||
                    chatData?.Users?.[0]?.FirstName ||
                    chatData?.Users?.[0]?.Title ||
                    "User",
                ].join(" ")
              )}
            />
          </div>
          <div className={styles.desc}>
            <h5 className={styles.name}>
              {`${chatData?.Users?.[0]?.LastName || ""} ${
                chatData?.Users?.[0]?.FirstName ||
                chatData?.Users?.[0]?.Title ||
                "User"
              }`}
            </h5>
            {chatData?.Users?.[0]?.Phone ? (
              <p className={styles.username}>+{chatData?.Users?.[0]?.Phone} </p>
            ) : (
              <>
                {chatType === "user" && (
                  <p className={styles.username}>
                    @{chatData?.Users?.[0]?.Username}{" "}
                  </p>
                )}
              </>
            )}
          </div>
        </Box>
        <Box className={styles.settings}>
          <div
            className={styles.panel}
            onClick={() => {
              dispatch(setOpenProfile(!openProfile));
            }}
          >
            <RightPanelIcon />
          </div>
          <ActionRenderer onOpen={onOpenUpdateContactModal} />
        </Box>
      </div>
      {pinMessages?.length ? (
        <div className={styles.pin}>
          <div className={styles.content}>
            <div className={styles.body}>
              <PinnedMessagesPagination
                pinMessages={pinMessages}
                currentPinnedIndex={currentPinnedIndex}
              />
              <div className={styles.desc}>
                <h4>Pinned Messages</h4>
                <p
                  onClick={() => {
                    scrollToMessage(pinMessages?.[currentPinnedIndex]?.ID);
                    cyclePinnedMessage();
                  }}
                >
                  {pinMessages?.[currentPinnedIndex]?.Message || "Файл"}
                </p>
              </div>
            </div>
            <Box
              className={styles.expandMore}
              onClick={() => setIsPinField(true)}
            >
              <ExpandMoreOutlined />
            </Box>
          </div>
        </div>
      ) : null}
      {openUpdateModal && (
        <ContactUpdateModal
          chatData={chatData}
          open={openUpdateModal}
          setOpen={setOpenUpdateModal}
        />
      )}
    </>
  );
};

export default FieldHeader;
