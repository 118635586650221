import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";
import FRow from "../../../../../../../components/FRow";
import { Controller, useForm } from "react-hook-form";

const PriceSection = ({ control }) => {
  return (
    <Box className={styles.infoCard}>
      <Typography variant="h6" className={styles.title}>
        Price
      </Typography>

      <div className={styles.body}>
        <FRow label="Nett price">
          <Controller
            name="net_price"
            key={"net_price"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter nett price"
                style={{
                  background: "#fff",
                }}
              />
            )}
          />
        </FRow>

        <FRow label="Gross price">
          <Controller
            name="grous_price"
            key={"grous_price"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter gross price"
                style={{
                  background: "#fff",
                }}
              />
            )}
          />
        </FRow>
      </div>
    </Box>
  );
};

export default PriceSection;
