import { Box, Button, Modal } from "@mui/material";
import SearchArea from "./SearchArea";
import useFlight from "../../../../../../hooks/useFlight";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import FlightForm from "./FlightForm";
import { useState } from "react";
import styles from "./styles.module.scss";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const FlightPopup = ({
  isOpen,
  toggle,
  addFlight,
  selectedFlight,
  changeFlight,
  setSelectedFlight,
}) => {
  const {
    flight,
    addRoute,
    setRoutes,
    removeRoute,
    changePrice,
    changeFlightType,
    changeRouteField,
    clearData,
  } = useFlight({ selectedFlight });

  const [isManualForm, setIsManualForm] = useState(
    selectedFlight?.id === "manual"
  );

  const onSave = () => {
    if (!flight?.routes[0]?.departureIataCode) return;

    if (!selectedFlight)
      addFlight({ ...flight, id: isManualForm ? "manual" : "auto" });
    else changeFlight({ ...flight, id: isManualForm ? "manual" : "auto" });

    toggle();
    clearData();
  };
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    background: "#fff",
  };
  return (
    <Modal
      open={isOpen}
      onClose={toggle}
      className={styles.order_popup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.card} style={modalStyle}>
        <Box className={styles.header}>
          <h5>{selectedFlight ? "Edit flight" : "Add flight"}</h5>
          <CloseOutlinedIcon onClick={toggle} />
        </Box>
        <Box className={styles.content}>
          <div>
            <Box display="flex" flexDirection="column">
              <SearchArea
                flightType={flight.flightType}
                changeFlightType={changeFlightType}
                changeRouteField={changeRouteField}
                setRoutes={setRoutes}
                isManualForm={isManualForm}
                setIsManualForm={setIsManualForm}
              />

              {isManualForm && (
                <FlightForm
                  flightType={flight.flightType}
                  routes={flight.routes}
                  setRoutes={setRoutes}
                  changeRouteField={changeRouteField}
                  addRoute={addRoute}
                  changePrice={changePrice}
                  removeRoute={removeRoute}
                />
              )}
            </Box>
          </div>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
          className={styles.footer}
        >
          <Button variant="outlinedSecondary" onClick={toggle}>
            Cancel
          </Button>
          <Button
            variant={
              !flight?.routes[0]?.departureIataCode
                ? "containedSecondary"
                : "containedSuccess"
            }
            disabled={!flight?.routes[0]?.departureIataCode}
            onClick={onSave}
          >
            <SaveOutlinedIcon
              style={{
                marginRight: "10px",
              }}
            />
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FlightPopup;
