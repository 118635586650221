import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";

import Content from "../../../../../components/Content";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import PaymentDetails from "./PaymentDetails";
import { NumericFormat } from "react-number-format";
import { useCallback } from "react";
import { orderSections } from "../..";
import { useSelector } from "react-redux";
import getHotelsTotalSum from "../../../../../utils/useGetHotelsPirce";
import PaymentContent from "../../../../../components/PaymentContent";

const Payments = () => {
  const navigate = useNavigate();
  const {
    orderId,
    payments,
    changePassengerType,
    changePassengerPrice,
    changePaymentStatus,
    switchPaymentVals,
    handleCreateOrder,
    isConfirmed,
    changeWeightUnit,
    changeWeightValue,
    changePackagePrice,
  } = useOutletContext();
  const { selectedHotels } = useSelector((store) => store.hotels);

  const onContinue = useCallback(() => {
    if (payments?.length === 0) return;
    if (!isConfirmed) handleCreateOrder();

    // navigate(`../${orderSections.PAYMENTS}`);
  }, [isConfirmed, handleCreateOrder]);

  const handleBack = () => {
    navigate(`../${orderSections.PASSENGERS}`);
  };

  const hotelSum = getHotelsTotalSum(selectedHotels, "grous_price");

  return (
    <Box width="100%" height="calc(100vh - 144px)" position="relative">
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100% - 56px)"
        overflow={"auto"}
        gap="20px"
        style={{
          overflowY: "scroll",
        }}
      >
        <PaymentContent title="Payment details">
          <PaymentDetails
            with_isurance={payments.with_isurance}
            with_hotel={payments.with_hotel}
            with_transport={payments.with_transport}
            status={payments.status}
            passengers={payments.passengers}
            passengers_payment_info={payments.passengers_payment_info}
            orderId={orderId}
            changePassengerPrice={changePassengerPrice}
            changePassengerType={changePassengerType}
            changePaymentStatus={changePaymentStatus}
            switchPaymentVals={switchPaymentVals}
            isConfirmed={isConfirmed}
            changeWeightUnit={changeWeightUnit}
            changeWeightValue={changeWeightValue}
            changePackagePrice={changePackagePrice}
          />
        </PaymentContent>
        <Box width="100%" bgcolor="white" padding="16px" borderRadius="12px">
          <Typography fontSize="18px" fontWeight={500}>
            Total price
          </Typography>

          <Box
            display="flex"
            justifyContent="space-between"
            width={391}
            my="4px"
          >
            <Typography fontSize="12px" fontWeight={400}>
              Hotel price
            </Typography>

            <Typography fontSize="14px" fontWeight={500}>
              <NumericFormat
                value={Number(hotelSum)}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={3}
                prefix="$"
              />
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width={391}
            my="4px"
          >
            <Typography fontSize="12px" fontWeight={400}>
              Package price
            </Typography>

            <Typography fontSize="14px" fontWeight={500}>
              <NumericFormat
                value={Number(payments?.package_price_gross || 0)}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={3}
                prefix="$"
              />
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            width={391}
            my="4px"
          >
            <Typography fontSize="12px" fontWeight={400}>
              Ticket cost
            </Typography>

            <Typography fontSize="14px" fontWeight={500}>
              <NumericFormat
                value={Number(payments.flight_price)}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={3}
                prefix="$"
              />
            </Typography>
          </Box>
          {/* <Box
            display="flex"
            justifyContent="space-between"
            width={391}
            my="4px"
          >
            <Typography fontSize="12px" fontWeight={400}>
              Insurance price
            </Typography>

            <Typography fontSize="14px" fontWeight={500}>
              <NumericFormat
                value={Number(
                  payments.with_isurance ? payments.isurance_price : 0
                )}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={3}
                prefix="$"
              />
            </Typography>
          </Box> */}
          <Box
            display="flex"
            justifyContent="space-between"
            width={391}
            my="4px"
          >
            <Typography fontSize="18px" fontWeight={400}>
              Total
            </Typography>

            <Typography fontSize="18px" fontWeight={500}>
              <NumericFormat
                value={
                  Number(payments.total_price + hotelSum) +
                  Number(payments?.package_price_gross || 0)
                }
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={3}
                prefix="$"
              />
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        position="absolute"
        left="-17px"
        bottom="-17px"
        width="calc(100% + 34px)"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        bgcolor="white"
        height={56}
        padding="8px"
        style={{
          "box-shadow": " 0px 1px 0px 0px rgba(229, 233, 235, 1) inset",
        }}
      >
        {isConfirmed && (
          <Button variant="outlinedSecondary" onClick={handleBack}>
            Back
          </Button>
        )}
        {!isConfirmed && (
          <Button variant="outlinedSecondary" onClick={onContinue}>
            Save
            <ArrowForwardIosOutlinedIcon
              style={{
                fontSize: "12px",
                marginLeft: 12,
              }}
            />
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Payments;
