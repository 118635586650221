import { Avatar, Box } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { stringSingleNameAvatar } from "../../../../utils/index.js";
import styles from "./styles.module.scss";

const ForwardSearchResult = ({ formattedContacts, handleForwardMessage }) => {
  return (
    <div className={styles.contact_box}>
      {formattedContacts?.map((item) => {
        return (
          <Box
            key={item?.id}
            className={classNames(styles.user)}
            onClick={() => {
              handleForwardMessage(item);
            }}
          >
            <div className={styles.userInfo}>
              <Avatar
                style={{
                  width: "40px",
                  height: "40px",
                }}
                {...stringSingleNameAvatar([item?.name].join(" "))}
              />
              <div className={styles.details}>
                <div className={styles.userName}>
                  {item?.deleted ? "Deleted account" : item?.name}
                </div>
              </div>
            </div>
          </Box>
        );
      })}
    </div>
  );
};

export default ForwardSearchResult;
