import { useEffect, useState } from "react";
import { flightTypes } from "../../../../../../../consts/flights";
import useAmadeus from "../../../../../../../hooks/useAmadeus";
import moment from "moment";

const initParams = {
  arrival_iataCode: null,
  departure_iataCode: null,
  arrival_at: null,
  departure_at: null,
};

const normalizer = (data) => ({
  originLocationCode:
    data?.departure_iataCode?.value || data?.departure_iataCode?.value,
  destinationLocationCode:
    data?.arrival_iataCode?.value || data?.arrival_iataCode?.value,
  departureDateTimeRange: {
    date: moment(data.departure_at).format("YYYY-MM-DD"),
  },
});
const leadFlightNormalizer = (data) => ({
  originLocationCode: data.city_from,
  destinationLocationCode: data.city_to,
  departureDateTimeRange: {
    date: moment(data.date_departure).format("YYYY-MM-DD"),
  },
});

export const useHook = ({ changeRouteField, setRoutes, flightType }) => {
  const { searchFlights } = useAmadeus();

  const [tickets, setTickets] = useState(null);
  const [loader, setLoader] = useState(false);
  const [includedCarriers, setIncludedCarriers] = useState([]);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [params, setParams] = useState([
    {
      ...initParams,
    },
  ]);

  const changeFlightParams = (index, key, value) => {
    setParams((prev) =>
      prev?.map((param, idx) =>
        idx === index ? { ...param, [key]: value } : param
      )
    );
  };

  const addRouteParam = () => setParams((prev) => [...prev, { ...initParams }]);

  const removeRouteParam = (index) =>
    setParams((prev) => prev.filter((_, idx) => idx !== index));

  const searchTickets = () => {
    setLoader(true);
    const originDestinations = params.map((data, d) => ({
      id: d + 1,
      ...normalizer(data),
    }));

    const isOneWay = flightType === flightTypes.ONE_WAY;
    const isRoundTrip = flightType === flightTypes.ROUND_TRIP;

    if (isRoundTrip)
      originDestinations.push({
        id: 2,
        originLocationCode: params[0].arrival_iataCode?.value,
        destinationLocationCode: params[0].departure_iataCode?.value,
        departureDateTimeRange: {
          date: moment(params[0].arrival_at).format("YYYY-MM-DD"),
        },
      });

    const flightFilters = {
      carrierRestrictions: {
        includedCarrierCodes: includedCarriers.map((carrier) => carrier.value),
      },
    };

    if (includedCarriers.length === 0) delete flightFilters.carrierRestrictions;

    searchFlights.mutate(
      {
        currencyCode: "USD",
        originDestinations,
        one_way: isOneWay,
        searchCriteria: {
          maxFlightOffers: 30,
          flightFilters,
        },
        travelers: [
          {
            id: "1",
            travelerType: "ADULT",
          },
        ],
        sources: ["GDS"],
      },
      {
        onSuccess: (res) => {
          setTickets(res.data);
          setLoader(false);
        },
        onError: (err) => {
          setLoader(false);
        },
      }
    );
  };
  const searchTicketsWithSegmentParams = (segmentParams) => {
    setLoader(true);

    const originDestinations = segmentParams?.map((data, d) => ({
      id: d + 1,
      ...leadFlightNormalizer(data),
    }));

    const isOneWay = segmentParams[0]?.type === flightTypes.ONE_WAY;
    const isRoundTrip = segmentParams[0]?.type === flightTypes.ROUND_TRIP;

    if (isRoundTrip) {
      originDestinations.push({
        id: 2,
        originLocationCode: segmentParams[0].city_to,
        destinationLocationCode: segmentParams[0].city_from,
        departureDateTimeRange: {
          date: moment(segmentParams[0].date_back).format("YYYY-MM-DD"),
        },
      });
    }

    const flightFilters = {
      // cabinRestrictions: [
      //   {
      //     cabin: segmentParams[0]?.cabin || "ECONOMY",
      //     coverage: "MOST_SEGMENTS",
      //     originDestinationIds: ["1"],
      //     // coverage: segmentParams[0]?.coverage,
      //   },
      // ],
      // connectionRestriction: {
      //   maxNumberOfConnections: Number(segmentParams[0]?.maxConnections) || 2,
      // },
      // baggageRestrictions: {
      //   includedCheckedBagsOnly:
      //     segmentParams[0]?.includedCheckedBagsOnly || false,
      // },
      // airportRestrictions: {
      //   excludedDepartureAirportCodes: segmentParams[0]
      //     ?.excludedDepartureAirportCodes?.length
      //     ? segmentParams[0]?.excludedDepartureAirportCodes?.map(
      //         (air) => air.value
      //       )
      //     : null,
      //   excludedArrivalAirportCodes: segmentParams[0]
      //     ?.excludedArrivalAirportCodes?.length
      //     ? segmentParams[0]?.excludedArrivalAirportCodes?.map(
      //         (air) => air.value
      //       )
      //     : null,
      // },
      // transferRestrictions: {
      //   maxTransferDuration: `PT${segmentParams[0]?.maxTransferDuration}H`,
      //   minTransferDuration: `PT1H`,
      // },
      // fareDetails: {
      //   fareType: [segmentParams[0]?.fareType],
      // },
      // advancedFilters: {
      //   maxJourneyDuration: `PT${segmentParams[0]?.maxFlightDuration}H`,
      // },
      // allianceRestrictions: {
      //   includedAlliances: segmentParams[0]?.includedAlliances,
      // },
      carrierRestrictions: {
        includedCarrierCodes: includedCarriers.map((carrier) => carrier.value),
      },
    };

    if (includedCarriers.length === 0) {
      delete flightFilters.carrierRestrictions;
    }

    searchFlights.mutate(
      {
        currencyCode: "USD",
        originDestinations,
        one_way: isOneWay,
        searchCriteria: {
          maxFlightOffers: 30,
          flightFilters,
        },
        travelers: [
          {
            id: "1",
            travelerType: "ADULT",
          },
        ],
        sources: ["GDS"],
      },
      {
        onSuccess: (res) => {
          setTickets(res.data);
          setLoader(false);
        },
        onError: (err) => {
          setLoader(false);
        },
      }
    );
  };

  const selectTicket = (ticket) => {
    setCurrentTicket(ticket);
  };

  useEffect(() => {
    if (!currentTicket) return;

    let routes = [];

    currentTicket.itineraries?.forEach((itinerary) =>
      itinerary.segments?.forEach((segment) =>
        routes.push({
          duration: segment.duration,
          departureIataCode: segment.departure.iataCode,
          departureTerminal: segment.departure.terminal,
          departure_at: segment.departure.at,
          arrivalIataCode: segment.arrival.iataCode,
          arrival_at: segment.arrival.at,
          carrierCode: segment.carrierCode,
        })
      )
    );

    setRoutes(
      routes,
      currentTicket?.grossPrice || currentTicket?.price?.total,
      currentTicket?.nettPrice || currentTicket?.price?.total
    );
  }, [currentTicket]);

  return {
    params,
    tickets,
    currentTicket,
    includedCarriers,
    loader,
    setIncludedCarriers,
    selectTicket,
    changeFlightParams,
    addRouteParam,
    removeRouteParam,
    searchTickets,
    searchTicketsWithSegmentParams,
    isSearchLoading: searchFlights.isLoading,
  };
};
