import { Box } from "@mui/material";
import React from "react";
import styles from "./style.module.scss";

const Sponsors = () => {
  const images = [
    { image: "/images/first_sponsor.png" },
    { image: "/images/second_sponsor.png" },
    { image: "/images/third_sponsor.png" },
    { image: "/images/fourth_sponsor.png" },
  ];

  return (
    <Box className={styles.section}>
      {images.map((item) => (
        <img src={item.image} alt="sponsors" />
      ))}
    </Box>
  );
};

export default Sponsors;
