import styles from "./style.module.scss";
import Tickets from "./tickets";
import SearchParams from "./SearchParams";
import classNames from "classnames";

import {
  flightClassesArray,
  flightTypes,
} from "../../../../../../../consts/flights";
import { useHook } from "./useHook";
import { FlightTypeRenders } from "./FlightTypes";
import { useState } from "react";
import { Box } from "@mui/material";

const SearchArea = ({
  flightType,
  flightClass = flightClassesArray[0].key,
  changeFlightType,
  changeFlightClass,
  changeRouteField,
  setRoutes,
  isManualForm,
  setIsManualForm,
}) => {
  const {
    params,
    tickets,
    currentTicket,
    includedCarriers,
    setIncludedCarriers,
    selectTicket,
    searchTickets,
    changeFlightParams,
    addRouteParam,
    removeRouteParam,
    isSearchLoading,
  } = useHook({ changeRouteField, setRoutes, flightType });

  const [isOpenTicketResult, setIsOpenTicketResult] = useState(true);

  const toggleTicketResult = () => setIsOpenTicketResult((prev) => !prev);

  const FlightTypeRender = FlightTypeRenders[flightType];
  return (
    <div className={classNames(styles.container, "search-area")}>
      <SearchParams
        isManualForm={isManualForm}
        setIsManualForm={setIsManualForm}
        flightType={flightType}
        flightClass={flightClass}
        ticketsCount={tickets?.length}
        changeFlightClass={changeFlightClass}
        changeFlightType={changeFlightType}
        toggleTicketResult={toggleTicketResult}
        searchTickets={searchTickets}
        includedCarriers={includedCarriers}
        setIncludedCarriers={setIncludedCarriers}
        isSearchLoading={isSearchLoading}
      />

      {!isManualForm && (
        <FlightTypeRender
          params={params}
          addRouteParam={addRouteParam}
          changeFlightParams={changeFlightParams}
          removeRouteParam={removeRouteParam}
        />
      )}

      <Box position="relative">
        {isOpenTicketResult && tickets && (
          <Tickets
            selectTicket={selectTicket}
            currentTicket={currentTicket}
            tickets={tickets}
            isRoundTrip={flightType === flightTypes.ROUND_TRIP}
          />
        )}
      </Box>
    </div>
  );
};

export default SearchArea;
