import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import styles from "./style.module.scss";
import useRoomOptionAPI from "../../../../hooks/api/useRoomOptionAPI";
import useUploadImage from "../../../../hooks/api/useUploadImage";
import { makeCDN } from "../../../../utils/uploadImage";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

const RoomOptionCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [image, setImage] = useState(null);
  const { roomOption, createRoomOption, updateRoomOption } = useRoomOptionAPI({
    roomOptionId: id,
  });
  const { uploadMutation, getImage } = useUploadImage();

  const { control, handleSubmit } = useForm({
    values: { ...(roomOption?.roomOption || {}) },
  });

  useEffect(() => {
    if (!roomOption) return;
    setImage(roomOption.roomOption?.icon);
  }, [roomOption]);

  const onClick = (vals) => {
    if (!!id) {
      updateRoomOption.mutate(
        {
          ...vals,
          icon: image,
        },
        {
          onSuccess: () => {
            toast.success("Room Option is edited successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Room Option!");
          },
        }
      );
    } else
      createRoomOption.mutate(
        {
          ...vals,
          icon: image,
        },
        {
          onSuccess: () => {
            toast.success("Room Option is added successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Room Option!");
          },
        }
      );
  };

  const onFileAdd = async (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    uploadMutation.mutate(e.target.files[0], {
      onSuccess: async (res) => {
        const resp = await getImage(res.$id);
        setImage(resp.href);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Room option" : "Add Room option"}
            height="80vh"
          >
            <Box width="100%" display="flex" gap="20px">
              <Box width="100%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
                <Box className={styles.field}>
                  <p className={styles.label}>Icon</p>
                  <label
                    htmlFor="imageUpload"
                    style={{
                      width: "243px",
                      height: "243px",
                      border: "2px dashed #c1c1c1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    {uploadMutation.isLoading ? (
                      <CircularProgress size={30} />
                    ) : image ? (
                      <img
                        src={makeCDN(image)}
                        width="100%"
                        height="100%"
                        alt="Upload"
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <AddAPhotoIcon />
                    )}

                    <input
                      type="file"
                      id="imageUpload"
                      onChange={onFileAdd}
                      style={{
                        display: "none",
                      }}
                    />
                  </label>
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createRoomOption.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createRoomOption.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default RoomOptionCreate;
