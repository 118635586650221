import { useState, useRef } from "react";
import moment from "moment";
import useChats from "../../../../hooks/api/useChatsAPI";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { TELEGRAM_CDN_URL } from "../../../../utils/telegramApi";

const useTool = ({ setLoader }) => {
  const { id, hashId } = useParams();
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState("");
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioMessage, setAudioMessage] = useState(null);
  const [multipleFiles, setMultipleFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [toolMessage, setToolMessage] = useState("");
  const [timer, setTimer] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const { uploadFile } = useChats({});

  const payload = {
    id: id,
    hashId: hashId,
    file_urls: audioMessage ? [audioMessage] : multipleFiles || undefined,
    message: message,
  };

  const onFileAdd = (file) => {
    setLoader(true);
    const formData = new FormData();
    const maxFileSize = 100 * 1024 * 1024;

    if (file.size > maxFileSize) {
      setLoader(false);
      toast.error(
        "File size exceeds the 100 MB limit. Please upload a smaller file."
      );
      return;
    }

    formData.append("file", file);
    uploadFile.mutate(
      { file: file },
      {
        onSuccess: (res) => {
          setAudioMessage(`${TELEGRAM_CDN_URL}${res?.payload?.link}`);
          setLoader(false);
        },
        onError: () => {
          setLoader(false);
          toast.error(
            "Something got wrong, please contact support to resolve this problem"
          );
        },
      }
    );
  };

  const clearAudio = () => {
    setAudioBlob(null);
    setAudioURL(null);
    setAudioMessage(null);
  };

  const options = {
    mimeType: "audio/webm;codecs=opus",
    audioBitsPerSecond: 32000,
  };

  const startRecording = () => {
    clearAudio();
    audioChunksRef.current = [];

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream, options);

        mediaRecorderRef.current.ondataavailable = (event) => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const blob = new Blob(audioChunksRef.current, { type: "audio/ogg" });
          const file = new File([blob], "recording.ogg", { type: "audio/ogg" });
          const url = URL.createObjectURL(file);
          onFileAdd(file);
          setAudioBlob(file);
          setAudioURL(url);
          audioChunksRef.current = [];
        };

        mediaRecorderRef.current.start();
        setRecording(true);
        startTimer();
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    stopTimer();
  };

  const startTimer = () => {
    setTimer(0);
    timerRef.current = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 100);
    }, 100);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = null;
  };

  const handleDeleteAudio = () => {
    setAudioURL("");
    setAudioBlob(null);
    setTimer(0);
    setRecording(false);
    stopTimer();
    clearAudio();
  };

  const formatTime = (milliseconds) => {
    const duration = moment.duration(milliseconds);
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const centiseconds = Math.floor(duration.milliseconds() / 90);
    return `${minutes < 10 ? "0" : ""}${minutes}:${
      seconds < 10 ? "0" : ""
    }${seconds}.${centiseconds}`;
  };

  return {
    recording,
    payload,
    audioURL,
    audioBlob,
    message,
    timer,
    audioMessage,
    multipleFiles,
    startRecording,
    stopRecording,
    handleDeleteAudio,
    formatTime,
    setMessage,
    setToolMessage,
    toolMessage,
    clearAudio,
    setMultipleFiles,
  };
};

export default useTool;
