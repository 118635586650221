import {
  Box,
  Button,
  CircularProgress,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import useContentAPI from "../../../../hooks/api/useContentAPI";
import useDirectionAPI from "../../../../hooks/api/useDirectionAPI";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Content from "../../../../components/Content";
import TSelect from "../../../../components/Select";
import Wrapper from "../../../../components/Wrapper";
import { publishStatusKeys, publishStatuses } from "../../../../consts/content";
import useCategoryAPI from "../../../../hooks/api/useCategoryAPI";
import { makeCDN } from "../../../../utils/uploadImage";
import styles from "./style.module.scss";
import moment from "moment";
import FormMultiAsyncSelect from "../../../../components/FormSelect/MultiAsyncSelect";
import useSearchFlightAPI from "../../../../hooks/api/useSearchFlightAPI";
import useUploadImage from "../../../../hooks/api/useUploadImage";
import { request } from "../../../../utils/api";

const ContentCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { uploadMutation, getImage } = useUploadImage();

  const { createContent, updateContent, content } = useContentAPI({
    contentId: id,
  });

  const { data, refetch, isLoading } = useSearchFlightAPI({
    searchFlightId: id,
    // amount: 2,
    enabled: true,
  });

  const { control, handleSubmit } = useForm({
    values: {
      ...(content?.content || {}),
      similar_flight_ids: data?.flights?.rows
        ?.filter((flight) =>
          content?.content?.similar_flight_ids?.includes(flight.id)
        )
        .map((item) => ({ name: item.title, id: item.id })),
    },
  });

  const flightOptions = useMemo(() => {
    return data?.flights?.rows?.map((item) => ({
      name: item?.title,
      id: item?.id,
    }));
  }, [data]);

  const { data: directions } = useDirectionAPI({
    enabled: true,
    page: 0,
    amount: 50,
  });

  const { data: categories } = useCategoryAPI({
    enabled: true,
    page: 0,
    amount: 50,
  });

  const [selectedDirection, setSelectedDirection] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!content) return;

    setSelectedCategory(content.content.category);
    setSelectedDirection(content.content.direction);
    setImage(content.content.image);
    setStatus(
      content.content.is_published ? publishStatuses[0] : publishStatuses[1]
    );
  }, [content]);

  const onClick = useCallback(
    (vals) => {
      if (!!id) {
        updateContent.mutate(
          {
            ...vals,
            direction_id: selectedDirection?.id,
            category_id: selectedCategory?.id,
            is_published: status.key === publishStatusKeys.PUBLISHED,
            expired_date: moment(vals.expired_date).format("YYYY-MM-DD"),
            similar_flight_ids: vals.similar_flight_ids?.map((item) => item.id),
            similar_flights: data?.flights?.rows?.filter((flight) =>
              vals.similar_flight_ids
                ?.map((item) => item.id)
                ?.includes(flight.id)
            ),
            image,
          },
          {
            onSuccess: () => {
              toast.success("Content is edited successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in editing content!");
            },
          }
        );
      } else
        createContent.mutate(
          {
            ...vals,
            direction_id: selectedDirection?.id,
            category_id: selectedCategory?.id,
            is_published: status.key === publishStatusKeys.PUBLISHED,
            expired_date: moment(vals.expired_date).format("YYYY-MM-DD"),
            similar_flight_ids: vals.similar_flight_ids?.map((item) => item.id),
            similar_flights: data?.flights?.rows?.filter((flight) =>
              vals.similar_flight_ids
                ?.map((item) => item.id)
                ?.includes(flight.id)
            ),
            image,
          },
          {
            onSuccess: () => {
              toast.success("Content is added successful!");
              navigate("../");
            },
            onError: () => {
              toast.error("Error in adding content!");
            },
          }
        );
    },
    [selectedCategory, selectedDirection, status, image]
  );

  const handleSearchFlights = async (query) => {
    if (!query?.length > 0) return [];

    const memoCities = flightOptions.filter((flight) => {
      return flight.id.toLowerCase().includes(query?.toLowerCase());
    });

    if (memoCities?.length > 0) return memoCities;

    if (query?.length < 3) return [];

    const res = await request.get("flight-search", {
      params: {
        search: query,
      },
    });

    return res?.flights?.rows?.map((fligth) => ({
      id: fligth.id,
      name: fligth.title,
    }));
  };

  const onFileAdd = async (e) => {
    const formData = new FormData();

    formData.append("file", e.target.files[0]);

    uploadMutation.mutate(e.target.files[0], {
      onSuccess: async (res) => {
        const resp = await getImage(res.$id);
        setImage(resp.href);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content title={!!id ? "Edit Content" : "Add Content"} height="80vh">
            <Box width="100%" display="flex" gap="20px">
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                gap="12px"
                marginTop="24px"
                flexWrap="wrap"
              >
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Title</p>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Description</p>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        required
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field}>
                  <p className={styles.label}>Category</p>
                  <TSelect
                    placeholder=""
                    value={selectedCategory}
                    loadOptions={() => categories?.categories?.rows}
                    defaultOptions={categories?.categories?.rows}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setSelectedCategory(e)}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>

                <Box className={styles.field}>
                  <p className={styles.label}>Direction</p>
                  <TSelect
                    placeholder=""
                    value={selectedDirection}
                    loadOptions={() => directions?.directions?.rows}
                    defaultOptions={directions?.directions?.rows}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setSelectedDirection(e)}
                    getOptionLabel={(opt) =>
                      [opt.airport_name, opt.iata_code].join(" ")
                    }
                    getOptionValue={(opt) => opt.id}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>

                <Box className={styles.field} marginTop="12px">
                  <p className={styles.label}>Status</p>
                  <TSelect
                    placeholder=""
                    value={status}
                    loadOptions={() => publishStatuses}
                    defaultOptions={publishStatuses}
                    components={{ IndicatorSeparator: null }}
                    onChange={(e) => setStatus(e)}
                    getOptionLabel={(opt) => opt.value}
                    getOptionValue={(opt) => opt.key}
                    styles={{
                      control: () => ({
                        backgroundColor: "#fff",
                        minWidth: "280px",
                        border: "1px solid rgba(229, 233, 235, 1)",
                      }),
                    }}
                  />
                </Box>

                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Expired Date</p>
                  <Controller
                    name="expired_date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="date"
                        className={styles.input}
                        required
                        value={moment(field.value).format("yyyy-MM-DD")}
                      />
                    )}
                  />
                </Box>
              </Box>

              <Box
                width="50%"
                display="flex"
                marginTop="24px"
                height="100%"
                alignItems="center"
                justifyContent="flex-start"
                flexDirection="column"
              >
                <label
                  htmlFor="imageUpload"
                  style={{
                    width: "243px",
                    height: "243px",
                    border: "2px dashed #c1c1c1",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  {uploadMutation.isLoading ? (
                    <CircularProgress size={30} />
                  ) : image ? (
                    <img
                      src={makeCDN(image)}
                      width="100%"
                      height="100%"
                      alt="Upload"
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <AddAPhotoIcon />
                  )}

                  <input
                    type="file"
                    id="imageUpload"
                    onChange={onFileAdd}
                    style={{
                      display: "none",
                    }}
                  />
                </label>

                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Price</p>
                  <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>

                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>Discount Price</p>
                  <Controller
                    name="discount_price"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>Meta title</p>
                  <Controller
                    name="meta_title"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>Meta description</p>
                  <Controller
                    name="meta_description"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>Meta keywords</p>
                  <Controller
                    name="meta_keywords"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%" marginTop="12px">
                  <p className={styles.label}>SEO text</p>
                  <Controller
                    name="seo_text"
                    control={control}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        className={styles.input}
                        minRows={5}
                      />
                    )}
                  />
                </Box>
                <Box className={styles.field} marginTop="12px">
                  <p className={styles.label}>Similar flights</p>
                  <FormMultiAsyncSelect
                    placeholder="Choose similar flights"
                    className={styles.select}
                    defaultOptions={flightOptions}
                    options={flightOptions}
                    getOptionLabel={(opt) => opt.name}
                    getOptionValue={(opt) => opt.id}
                    control={control}
                    name={`similar_flight_ids`}
                    isMulti
                    loadOptions={handleSearchFlights}
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createContent.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createContent.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default ContentCreate;
