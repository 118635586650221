import styles from "./style.module.scss";
import { ReactComponent as ArrivalFlightIcon } from "../../assets/icons/arrival_plane.svg";
import { ReactComponent as DepartureFlightIcon } from "../../assets/icons/departure_plane.svg";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import classNames from "classnames";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";
import { airlineLogos } from "../../consts/flights";
const airports = require("airport-codes/airports.json");

const calculateDuration = (val) => {
  const removedPt = val?.replace("PT", "");
  const splittedHrs = removedPt?.split("H");
  const hours = splittedHrs?.[0];
  const splittedMinutes = splittedHrs?.[1]?.split("M");
  const minutes = splittedMinutes?.[0];
  return [hours, minutes].filter((item) => !!item).join(":");
};

const calculateLayover = (prev_itin_route_time, this_route_time) => {
  let a = moment(prev_itin_route_time);
  let b = moment(this_route_time);

  const total_hours = b.diff(a, "hours");
  const total_mins = b.diff(a, "minutes");

  return `${total_hours}h ${total_mins - total_hours * 60}m`;
};

const TicketForPq = ({
  data,
  isActive,
  onlyRead = false,
  isRoundTrip,
  clientMode,
}) => {
  const [logosCache, setLogosCache] = useState({});

  const fetchAirlineLogo = useCallback(
    async (carrierCode) => {
      if (logosCache[carrierCode]) return;

      try {
        const apiUrl = `https://api.api-ninjas.com/v1/airlines?iata=${carrierCode}`;
        const response = await fetch(apiUrl, {
          headers: { "X-Api-Key": "yY/cGjvpuzPG0QLxgyl14g==u1hjIuYEMjmmGZyw" },
        });
        const data = await response.json();

        if (Array.isArray(data) && data.length > 0) {
          const airlineInfo = data[0];
          if (airlineInfo.logo_url) {
            setLogosCache((prev) => ({
              ...prev,
              [carrierCode]: airlineInfo.logo_url,
            }));
          }
        }
      } catch (err) {
        console.error("Ошибка при загрузке авиакомпании", err);
      }
    },
    [logosCache]
  );

  const layovers = useMemo(() => {
    const _lays = [];
    const segments = [];

    data?.itineraries?.forEach((itin, i) =>
      itin.segments?.forEach((segm, s) => {
        segments.push(segm);
      })
    );

    segments?.forEach((segm, idx) => {
      if (idx === 0) return;
      const calculatedTime = calculateLayover(
        segments[idx - 1].arrival.at,
        segm.departure.at
      );

      const hours = Number(calculatedTime.split("h")[0]);

      if (hours > 24) _lays.push("roundtrip");
      else _lays.push(calculatedTime);
    });

    if (isRoundTrip) _lays[Math.floor(_lays.length / 2)] = "roundtrip";

    return _lays;
  }, [data, isRoundTrip]);

  useEffect(() => {
    if (!data?.itineraries) return;

    const carrierCodes = [];
    data.itineraries.forEach((itinerary) => {
      itinerary.segments.forEach((seg) => {
        if (!carrierCodes.includes(seg.carrierCode)) {
          carrierCodes.push(seg.carrierCode);
        }
      });
    });

    carrierCodes.forEach((code) => {
      fetchAirlineLogo(code);
    });
  }, [data, fetchAirlineLogo]);

  return (
    <div
      className={classNames(styles.ticket_for_pq, {
        [styles.active]: isActive,
        [styles.onlyRead]: onlyRead,
      })}
    >
      {data?.itineraries?.map((itinerary, it_idx) =>
        itinerary?.segments?.map((route, r) => {
          const itins = [...data?.itineraries];
          const initSegmentCount =
            itins
              .splice(0, it_idx)
              .map((itin) => itin.segments.length)
              .reduce((prev, curr) => {
                return prev + curr;
              }, 0) + r;

          const layover =
            it_idx >= 1 ? layovers[initSegmentCount] : layovers[r];

          const airlineLogoUrl = logosCache[route.carrierCode];

          return (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <div key={r} className={classNames(styles.route)}>
                <div className={styles.left}>
                  <img
                    src={
                      airlineLogos?.find((item) =>
                        item?.includes(route.carrierCode)
                      ) ||
                      `https://content.airhex.com/content/logos/airlines_${route.carrierCode}_85_30_r.png?proportions=keep`
                    }
                    alt={route.carrierCode}
                    crossOrigin="anonymous"
                  />

                  <h3>{moment(route.departure.at).format("HH:mm")}</h3>
                  <Box display="flex" flexDirection="column">
                    <p>
                      {airports &&
                        airports?.find(
                          (item) => item?.iata === route.departure.iataCode
                        )?.city}
                    </p>
                    <p>{moment(route.departure.at).format("MMMM DD")}</p>
                  </Box>
                </div>
                <div className={styles.middle}>
                  <div className={styles.date}>
                    <DepartureFlightIcon />
                    {!!calculateDuration(route?.duration) && (
                      <>
                        {calculateDuration(route?.duration)}&nbsp;{" "}
                        {calculateDuration(route?.duration).includes("M")
                          ? null
                          : "hours"}
                      </>
                    )}
                    <ArrivalFlightIcon />
                  </div>
                  <div className={styles.iatas}>
                    <p>{route.departure.iataCode}</p>
                    <p>{route.arrival.iataCode}</p>
                  </div>
                </div>
                <div className={styles.right}>
                  <img
                    alt={route.carrierCode}
                    crossOrigin="anonymous"
                    src={
                      airlineLogos?.find((item) =>
                        item?.includes(route.carrierCode)
                      ) ||
                      `https://content.airhex.com/content/logos/airlines_${route.carrierCode}_85_30_r.png?proportions=keep`
                    }
                  />
                  <h3>{moment(route.arrival.at).format("HH:mm")}</h3>
                  <Box display="flex" flexDirection="column">
                    <p>
                      {airports &&
                        airports?.find(
                          (item) => item?.iata === route.arrival.iataCode
                        )?.city}
                    </p>
                    <p>{moment(route.arrival.at).format("MMMM DD")}</p>
                  </Box>
                </div>
              </div>

              {!!layover ? (
                layover === "roundtrip" ? (
                  <Box
                    display="flex"
                    width="90%"
                    height="0"
                    borderTop="1px solid var(--primary-color)"
                    justifyContent="center"
                    alignItems="center"
                    my={2}
                  ></Box>
                ) : (
                  <Box
                    display="flex"
                    width="100%"
                    height={20}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <HistoryToggleOffIcon color="error" />
                    <Typography
                      color="error"
                      fontSize="12px"
                      fontWeight={600}
                      ml={1}
                    >
                      Layover - {layover}
                      &nbsp;
                    </Typography>
                  </Box>
                )
              ) : (
                ""
              )}
            </Box>
          );
        })
      )}
      <div
        className={classNames(styles.client_mode, {
          [styles.client_mode_active]: clientMode,
        })}
      >
        <p>
          Price:{" "}
          <span>
            <NumericFormat
              value={data?.grossPrice?.total || data?.grossPrice}
              displayType="text"
              decimalScale={2}
              thousandSeparator
              prefix="$"
            />
          </span>
        </p>
        <p>
          Baggage Info:{" "}
          <span>{data?.weightValue + " " + data?.weightType}</span>
        </p>
      </div>
    </div>
  );
};

export default TicketForPq;
