import { createSlice } from "@reduxjs/toolkit";
import { paymentTypes } from "../../consts/flights";

const initialState = {
  passengers: [],
  flights: [],
  exchangePassengers: [],
  payments: {
    total_price: 0,
    flight_price: 0,
    isurance_price: 0,
    with_isurance: true,
    with_hotel: true,
    with_transport: true,
    package_price_gross: null,
    package_price_nett: null,
    status: paymentTypes.PENDING,
    passengers: [],
  },
  lead: {
    segments: [],
    type: "",
    status: "",
  },
  status: "all",
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setPassengers: (state, { payload }) => {
      state.passengers = payload;
    },
    setFlights: (state, { payload }) => {
      state.flights = payload;
    },
    setExchangePassengers: (state, { payload }) => {
      state.exchangePassengers = payload;
    },
    setPayments: (state, { payload }) => {
      state.payments = payload;
    },
    setLead: (state, { payload }) => {
      state.lead = payload;
    },
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
    clearOrder: (state) => {
      state.passengers = [];
      state.flights = [];
      state.payments = { ...initialState.payments };
      state.lead = {};
    },
  },
});
export const {
  setFlights,
  setPassengers,
  setPayments,
  clearOrder,
  setLead,
  setStatus,
  setExchangePassengers,
} = orderSlice.actions;

export default orderSlice.reducer;
