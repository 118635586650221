import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { NotePadHeaderIcon } from "../../../../assets/icons";
import useChats from "../../../../hooks/api/useChatsAPI";
import styles from "./style.module.scss";
import "./style.scss";

export default function ChatNotePadMenu({
  open,
  onClose,
  anchorEl,
  noteControl,
  title = "Notepad",
}) {
  const { id, hashId } = useParams();
  const [loader, setLoader] = useState(false);

  const { notepadPatch } = useChats({
    hash: hashId,
    id: id,
  });

  const note = useWatch({
    control: noteControl.control,
    name: "note",
  });

  const [debouncedNote] = useDebounce(note, 500);

  const payload = {
    fields: {
      notepad: debouncedNote,
    },
  };

  useEffect(() => {
    if (debouncedNote !== undefined) {
      setLoader(true);
      notepadPatch.mutateAsync(payload, {
        onSuccess: () => {
          setLoader(false);
        },
        onError: () => {
          setLoader(false);
        },
      });
    }
  }, [debouncedNote]);

  return (
    <Popover
      id="note-menu"
      className={styles.menu}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <div className={styles.header}>
        <div className={styles.content}>
          <NotePadHeaderIcon />
          <div>
            <p className={styles.title}>{title}</p>
          </div>
          {loader && <CircularProgress size={15} />}
        </div>
        <CloseIcon onClick={onClose} className={styles.close} />
      </div>
      <div className={styles.body}>
        <Controller
          name="note"
          control={noteControl.control}
          render={({ field }) => (
            <TextareaAutosize
              {...field}
              placeholder="Enter a description..."
              className={styles.input}
            />
          )}
        />
      </div>
    </Popover>
  );
}
