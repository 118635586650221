import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useChats from "../../hooks/api/useChatsAPI";
import { useDispatch } from "react-redux";
import { setToggleAccess } from "../../redux/chat/chatSlice";
import { store } from "../../redux/store";

const useLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [statedChats, setStatedChats] = useState([]);
  const [permission, setPermission] = useState(null);
  const audio = new Audio("/sound/Notification2.mp3");
  const access = store.getState().chat.access;
  const onChatSuccess = (res) => {
    const lastPrevChat = statedChats?.[0];
    const newChat = res?.pages?.[0]?.payload?.chats?.[0];

    if (
      lastPrevChat &&
      newChat &&
      lastPrevChat?.LastMessage?.ID !== newChat?.LastMessage?.ID
    ) {
      sendNotification(newChat);
    }

    setStatedChats(res?.pages?.[0]?.payload?.chats);
    return res;
  };

  const onChatError = (err) => {
    if (err?.data?.message === "Access denied") {
      dispatch(setToggleAccess(false));
    }
    return err;
  };

  const {
    data: chats,
    refetch: chatsRefetch,
    isLoading: chatsIsLoading,
    chatIsFetching,
    isError: chatsIsError,
    chatFetchNextPage,
    chatHasNextPage,
  } = useChats({
    onChatSuccess,
    onChatError,
    access,
  });

  useEffect(() => {
    if ("Notification" in window) {
      setPermission(Notification.permission);

      if (Notification.permission === "default") {
        Notification.requestPermission().then((permission) => {
          setPermission(permission);
        });
      }
    } else {
      alert("Your browser does not support notifications.");
    }
  }, []);

  const sendNotification = (data) => {
    if (permission === "granted" && !data?.LastMessage?.Out) {
      audio.play();
      const notification = new Notification(data?.Chat?.FirstName, {
        body: data?.LastMessage?.Message,
      });
      notification.onclick = function (event) {
        event.preventDefault();
        navigate(`/chat/${data?.Chat?.ID}/${data?.Chat?.AccessHash}`);
      };
    } else {
      return;
    }
  };

  return {
    chats,
    chatsIsLoading,
    chatIsFetching,
    chatsRefetch,
    chatsIsError,
    chatFetchNextPage,
    chatHasNextPage,
  };
};

export default useLayout;
