import React, { useMemo } from "react";
import moment from "moment";
import classNames from "classnames";
import { Box } from "@mui/material";
import styles from "./style.module.scss";
import { airlineLogos } from "../../../../consts/flights";

function calculateLayover(prevArrival, nextDeparture) {
  const a = moment(prevArrival);
  const b = moment(nextDeparture);
  const totalHours = b.diff(a, "hours");
  const totalMins = b.diff(a, "minutes");
  return `${totalHours}h ${totalMins - totalHours * 60}m`;
}

function splitSegments(segments) {
  if (!segments || !segments.length) {
    return [];
  }

  segments.sort(
    (a, b) =>
      new Date(a.departure.at).getTime() - new Date(b.departure.at).getTime()
  );

  const DAY_IN_MS = 24 * 60 * 60 * 1000;
  const itineraries = [];
  let currentSegments = [];

  for (let i = 0; i < segments.length; i++) {
    const seg = segments[i];
    if (currentSegments.length === 0) {
      currentSegments.push(seg);
    } else {
      const prev = currentSegments[currentSegments.length - 1];

      const prevArrivalIata = prev.arrival.iataCode;
      const curDepartureIata = seg.departure.iataCode;

      const prevArrTime = new Date(prev.arrival.at).getTime();
      const curDepTime = new Date(seg.departure.at).getTime();
      const diffMs = curDepTime - prevArrTime;

      const sameIata = prevArrivalIata === curDepartureIata;
      const shortGap = diffMs >= 0 && diffMs < DAY_IN_MS;

      if (sameIata && shortGap) {
        currentSegments.push(seg);
      } else {
        itineraries.push({ segments: currentSegments });
        currentSegments = [seg];
      }
    }
  }
  if (currentSegments.length > 0) {
    itineraries.push({ segments: currentSegments });
  }

  return itineraries;
}

function computeItinerariesWithLayovers(itineraries, isRoundTrip) {
  return itineraries.map((it) => {
    const segs = it.segments;
    const layovers = [];

    for (let i = 1; i < segs.length; i++) {
      const lay = calculateLayover(
        segs[i - 1].arrival.at,
        segs[i].departure.at
      );
      const hours = Number(lay.split("h")[0]);
      if (hours > 24) layovers.push("roundtrip");
      else layovers.push(lay);
    }

    return {
      segments: segs,
      layovers,
    };
  });
}

export default function NewTicketVariant({
  data,
  onClick,
  isActive,
  onlyRead = false,
  isRoundTrip,
}) {
  const allSegments = data.itineraries.flatMap((it) => it.segments);

  const splittedItineraries = useMemo(
    () => splitSegments(allSegments),
    [allSegments]
  );

  const finalItineraries = useMemo(
    () => computeItinerariesWithLayovers(splittedItineraries, isRoundTrip),
    [splittedItineraries, isRoundTrip]
  );

  return (
    <>
      {finalItineraries.map((it, idx) => {
        const segments = it.segments;
        const layovers = it.layovers;

        const fromIata = segments[0]?.departure?.iataCode || "From";
        const toIata = segments[segments.length - 1]?.arrival?.iataCode || "To";

        return (
          <div
            key={`ticket-card-${idx}`}
            className={classNames(styles.ticket, {
              [styles.active]: isActive,
              [styles.onlyRead]: onlyRead,
              [styles.more]: segments?.length > 1,
            })}
            onClick={() => !onlyRead && onClick?.(data)}
          >
            <div
              className={styles.ticketHeader}
            >{`${fromIata} → ${toIata}`}</div>

            <div className={styles.ticketBody}>
              {segments.map((segment, segIndex) => {
                const layover = layovers[segIndex];

                const airlineName =
                  segment.airlineName || segment.carrierCode || "Airline";

                const flightDuration =
                  segment.duration
                    ?.replace("PT", "")
                    ?.replace("H", "h ")
                    ?.replace("M", "m") || "";

                return (
                  <React.Fragment key={`seg-${segIndex}`}>
                    <div className={styles.flightSegment}>
                      <div className={styles.airline}>
                        <img
                          src={
                            airlineLogos?.find((item) =>
                              item?.includes(segment.carrierCode)
                            ) ||
                            `https://content.airhex.com/content/logos/airlines_${segment.carrierCode}_85_30_r.png?proportions=keep`
                          }
                          alt={segment.carrierCode}
                        />
                        <Box className={styles.desc}>
                          <div className={styles.airlineName}>
                            {airlineName}
                          </div>
                          <div className={styles.flightDuration}>
                            {flightDuration} in flight
                          </div>
                        </Box>
                      </div>

                      <div className={styles.flightInfo}>
                        <div className={styles.timeline}>
                          <span className={styles.dot} />
                          <span className={styles.line} />
                          <span className={styles.dot} />
                        </div>

                        <div className={styles.details}>
                          <div className={styles.departure}>
                            <Box className={styles.dates}>
                              <div className={styles.time}>
                                {moment(segment.departure.at).format("HH:mm")}
                              </div>
                              <div className={styles.date}>
                                {moment(segment.departure.at).format(
                                  "DD MMM, ddd"
                                )}
                              </div>
                            </Box>
                            <Box>
                              <div className={styles.content}>
                                <h6>{segment.departure.iataCode}</h6>
                              </div>
                              <div className={styles.airport}>
                                {segment.departure.iataCode},{" "}
                                {segment.departure.airport || "Airport"}
                              </div>
                            </Box>
                          </div>

                          <div className={styles.arrival}>
                            <Box className={styles.dates}>
                              <div className={styles.time}>
                                {moment(segment.arrival.at).format("HH:mm")}
                              </div>
                              <div className={styles.date}>
                                {moment(segment.arrival.at).format(
                                  "DD MMM, ddd"
                                )}
                              </div>
                            </Box>
                            <Box>
                              <div className={styles.content}>
                                <h6>{segment.arrival.iataCode}</h6>
                              </div>
                              <div className={styles.airport}>
                                {segment.arrival.iataCode},{" "}
                                {segment.arrival.airport || "Airport"}
                              </div>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>

                    {layover && layover !== "roundtrip" && (
                      <div className={styles.layover}>
                        <span className={styles.layoverLabel}>
                          • Layover: {layover}
                        </span>
                      </div>
                    )}
                    {layover === "roundtrip" && (
                      <div className={styles.roundtripSeparator} />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}
