import { Dialog } from "@mui/material";
import styles from "./style.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import toast from "react-hot-toast";
import useChats from "../../../../hooks/api/useChatsAPI";

export default function UnassignConfirmModal({
  open,
  userId,
  onClose,
  refetchActiveUsers,
}) {
  const { unassignAll } = useChats();

  const handleSubmit = () => {
    onClose();

    const payload = {
      user_key: userId,
    };
    toast.promise(
      unassignAll.mutateAsync(payload, {
        onSuccess: refetchActiveUsers,
      }),
      {
        loading: "Unassigning...",
        success: <b>Unassigned successful!</b>,
        error: <b>Error on unassigning.</b>,
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <div className={styles.box}>
        <CloseIcon onClick={onClose} className={styles.close} />
        <p>Are you sure you want to unassign all chats from this user?</p>
        <div className={styles.buttons}>
          <button onClick={onClose}>No</button>
          <button onClick={handleSubmit}>Yes</button>
        </div>
      </div>
    </Dialog>
  );
}
