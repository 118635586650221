import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../components/Content";
import Wrapper from "../../../../components/Wrapper";
import styles from "./style.module.scss";
import useRoomTypeAPI from "../../../../hooks/api/useRoomTypeAPI";
import FormMultiAsyncSelect from "../../../../components/FormSelect/MultiAsyncSelect";
import useRoomOptionAPI from "../../../../hooks/api/useRoomOptionAPI";

const RoomTypeCreate = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { roomType, createRoomType, updateRoomType } = useRoomTypeAPI({
    roomTypeId: id,
  });

  const { data, isLoading, refetch } = useRoomOptionAPI({
    enabled: true,
    limit: 50,
  });

  const roomOptions = useMemo(() => {
    return data?.roomOptions?.rows?.map((item) => ({
      label: item?.name,
      value: item?.id,
    }));
  }, [data]);

  const { control, handleSubmit } = useForm({
    values: {
      ...(roomType?.roomType || {}),
      room_option_ids: data?.roomOptions?.rows
        ?.filter((item) =>
          roomType?.roomType?.roomOptions
            ?.map((item) => item.id)
            .includes(item.id)
        )
        .map((item) => ({ label: item.name, value: item.id })),
    },
  });

  const onClick = useCallback((vals) => {
    if (!!id) {
      updateRoomType.mutate(
        {
          ...vals,
          room_option_ids: vals.room_option_ids?.map((item) => item.value),
        },
        {
          onSuccess: () => {
            toast.success("Room Type is edited successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in editing Room Type!");
          },
        }
      );
    } else
      createRoomType.mutate(
        {
          ...vals,
          room_option_ids: vals.room_option_ids?.map((item) => item.value),
        },
        {
          onSuccess: () => {
            toast.success("Room Type is added successful!");
            navigate("../");
          },
          onError: () => {
            toast.error("Error in adding Room Type!");
          },
        }
      );
  }, []);

  return (
    <form onSubmit={handleSubmit(onClick)}>
      <Wrapper>
        <div>
          <Content
            title={!!id ? "Edit Room type" : "Add Room type"}
            height="80vh"
          >
            <Box width="100%" display="flex" gap="20px">
              <Box width="100%" display="flex" gap="12px" marginTop="24px">
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Name</p>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField {...field} className={styles.input} required />
                    )}
                  />
                </Box>
                <Box className={styles.field} width="100%">
                  <p className={styles.label}>Room options</p>
                  <FormMultiAsyncSelect
                    placeholder="Choose similar options"
                    className={styles.select}
                    defaultOptions={roomOptions}
                    options={roomOptions}
                    control={control}
                    name={`room_option_ids`}
                    isMulti
                  />
                </Box>
              </Box>
            </Box>
          </Content>
        </div>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            type="submit"
            variant="outlinedSecondary"
            disabled={createRoomType.isLoading}
            style={{
              width: "120px",
            }}
          >
            {createRoomType.isLoading ? (
              <CircularProgress size={22} />
            ) : !!id ? (
              "Save"
            ) : (
              "Create"
            )}
          </Button>
        </Box>
      </Wrapper>
    </form>
  );
};

export default RoomTypeCreate;
