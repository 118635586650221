import { toast } from "react-hot-toast";

export const isUserAdmin = (role) =>
  role === "8dcc49a6-4ef4-11ee-be56-0242ac120002";

export const isUserSupervisor = (role) =>
  role === "ccffdf1b-eba1-4eb8-9047-1457caeef2d1";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 52,
      height: 52,
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}
export function stringSingleAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 52,
      height: 52,
    },
    children: `${name?.toUpperCase().split(" ")[0][0] || "?"}`,
  };
}
export function stringSingleNameAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 36,
      height: 36,
    },
    children: `${name?.toUpperCase().split(" ")[0][0] || "?"}`,
  };
}

export function copyNavigator(val, justValue = false) {
  const FORM_LINK =
    process.env.REACT_APP_FORM_LINK || "https://test.form.tripoasia.com/";

  if ("clipboard" in navigator) {
    if (justValue) navigator.clipboard.writeText(val);
    else navigator.clipboard.writeText(FORM_LINK + val);

    toast.success("Copied!");
  } else {
    const textArea = document.createElement("textarea");

    if (justValue) textArea.value = val;
    else textArea.value = FORM_LINK + val;

    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const success = document.execCommand("copy");
      if (success) toast.success("Copied!");
    } catch (err) {
      console.error(err.name, err.message);
    }
    document.body.removeChild(textArea);
  }
}
