import { genderTypesArray } from "../../../../../consts/genders";
import styles from "./style.module.scss";
import { Box, TextField, Typography } from "@mui/material";

export default function Customers({
  customers,
  onChangeComment,
  comments,
  customerPayments,
}) {
  return (
    <Box className={styles.section}>
      <Typography variant="h5" className={styles.title}>
        Passengers
      </Typography>
      <div className={styles.items}>
        {customers?.map((item, i) => (
          <div className={styles.item} key={item.id}>
            <Box className={styles.header}>
              <Typography className={styles.header_title}>
                Passenger {i + 1}
              </Typography>
              &nbsp;
              <Typography className={styles.header_text}>
                ({genderTypesArray.find((gn) => gn.key === item.gender).value})
              </Typography>
            </Box>
            <Box width="100%" className={styles.wrapper}>
              <div className={styles.info}>
                <Box className={styles.desc}>
                  <Typography className={styles.key}>First Name</Typography>
                  <p className={styles.name}>{item.first_name}</p>
                </Box>
                <Box className={styles.desc}>
                  <Typography className={styles.key}>Last Name</Typography>
                  <p className={styles.name}>{item.last_name}</p>
                </Box>
                <Box className={styles.desc}>
                  <Typography className={styles.key}>Pasport ID</Typography>
                  <p className={styles.name}>{item.passport_number}</p>
                </Box>
                <Box className={styles.desc}>
                  <Typography className={styles.key}>Date of birth</Typography>
                  <p className={styles.name}>{item.date_of_birth}</p>
                </Box>
              </div>
              <div className={styles.element}>
                <Typography className={styles.name}>Baggage: </Typography>
                <p className={styles.key}>
                  {[
                    JSON.parse(customerPayments)?.find(
                      (cusPay) =>
                        cusPay.fullname ===
                        [item.first_name, item.last_name].join(" ")
                    )?.weight?.value,
                    JSON.parse(customerPayments)?.find(
                      (cusPay) =>
                        cusPay.fullname ===
                        [item.first_name, item.last_name].join(" ")
                    )?.weight?.unit,
                  ].join(" ")}
                </p>
              </div>
            </Box>
            <Box alignItems="center" className={styles.comment}>
              <TextField
                label="Comment"
                disabled
                value={
                  comments.find((comment) => comment.customer_id === item.id)
                    ?.comment
                }
                onChange={(e) => onChangeComment(e, i)}
              />
            </Box>
          </div>
        ))}
      </div>
    </Box>
  );
}
