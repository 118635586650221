import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOpenProfile } from "../redux/chat/chatSlice";

const useExitChatOnEsc = ({ setOpen, setReply, setIsPinField }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
        setReply({});
        setIsPinField(false);
        navigate(-1);
        dispatch(setOpenProfile(false));
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [setOpen, setReply, setIsPinField, navigate]);
};

export default useExitChatOnEsc;
