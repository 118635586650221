import { Box, Switch, TextField, Typography } from "@mui/material";
import styles from "./style.module.scss";
import TSelect from "../../../../../../components/Select";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { passengerTypesArray } from "../../../../../../consts/genders";
import {
  paymentTypesArray,
  weightTypes,
  weightTypesArray,
} from "../../../../../../consts/flights";

import { copyNavigator } from "../../../../../../utils";
import { useSelector } from "react-redux";

const PaymentDetails = ({
  status,
  passengers,
  changePassengerPrice,
  changePassengerType,
  changePaymentStatus,
  changeWeightUnit,
  changeWeightValue,
  switchPaymentVals,
  with_isurance,
  passengers_payment_info,
  with_hotel,
  orderId,
  with_transport,
  isConfirmed,
  changePackagePrice,
}) => {
  const { flights, payments, lead } = useSelector((store) => store.order);
  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(passengerTypesArray);
      }, 1000);
    });

  const promiseOptionsWeights = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(weightTypesArray);
      }, 1000);
    });

  const onChangeType = (e, index) => {
    changePassengerType(index, e.key);
  };

  const onChangeWeightUnit = (e, index) => {
    changeWeightUnit(index, e.key);
  };

  const onSwitch = (e) => {
    switchPaymentVals(e.target.name, e.target.checked);
  };

  const onChangePaymentStatus = (e) => changePaymentStatus(e.key);

  const FORM_LINK =
    process.env.REACT_APP_FORM_LINK || "https://test.form.tripoasia.com/";

  return (
    <div className={styles.details}>
      <div className={styles.paymentInformation}>
        <h4>Per passenger ticket price</h4>

        {passengers.map((passenger, p) => (
          <div className={styles.box} key={p}>
            <p>{passenger.fullname}</p>

            <TextField
              type="number"
              className={styles.field}
              value={passenger?.ticket_price_nett}
              onChange={({ target }) =>
                changePassengerPrice(p, "ticket_price_nett", target.value)
              }
              placeholder="Nett"
              disabled={isConfirmed}
            />

            <TextField
              type="number"
              className={styles.field}
              placeholder="Gross"
              value={passenger?.ticket_price_gross}
              onChange={({ target }) =>
                changePassengerPrice(p, "ticket_price_gross", target.value)
              }
              disabled={isConfirmed}
            />

            <div className={styles.field}>
              <TSelect
                placeholder=""
                value={passengerTypesArray.find(
                  (type) => type.key === passenger.type
                )}
                loadOptions={promiseOptions}
                defaultOptions={passengerTypesArray}
                components={{ IndicatorSeparator: null }}
                getOptionLabel={(opt) => opt.value}
                getOptionValue={(opt) => opt.key}
                onChange={(e) => onChangeType(e, p)}
                className={isConfirmed && "disabled"}
                styles={{
                  control: () => ({
                    backgroundColor: "#fff",
                    border: "1px solid rgba(229, 233, 235, 1)",
                    minWidth: "auto",
                  }),
                  container: (styles) => ({
                    ...styles,
                    width: "100%",
                  }),
                }}
              />
            </div>

            <TextField
              type="number"
              className={styles.field}
              value={
                passenger.weight.unit === weightTypes.NO
                  ? null
                  : passenger.weight.value
              }
              onChange={({ target }) => changeWeightValue(p, target.value)}
              disabled={isConfirmed || passenger.weight.unit === weightTypes.NO}
              placeholder="Enter weights"
            />

            <div className={styles.field}>
              <TSelect
                placeholder="Weight Unit"
                value={weightTypesArray.find(
                  (type) => type.key === passenger?.weight?.unit
                )}
                loadOptions={promiseOptionsWeights}
                defaultOptions={weightTypesArray}
                components={{ IndicatorSeparator: null }}
                getOptionLabel={(opt) => opt.value}
                getOptionValue={(opt) => opt.key}
                onChange={(e) => onChangeWeightUnit(e, p)}
                className={isConfirmed && "disabled"}
                styles={{
                  control: () => ({
                    backgroundColor: "#fff",
                    border: "1px solid rgba(229, 233, 235, 1)",
                    minWidth: "auto",
                  }),
                  container: (styles) => ({
                    ...styles,
                    width: "100%",
                  }),
                }}
              />
            </div>
          </div>
        ))}
        <div className={styles.box}>
          <p className={styles.package_text}>Package price</p>

          <TextField
            type="number"
            value={payments?.package_price_nett}
            className={styles.package}
            fullWidth
            placeholder="Nett"
            onChange={(e) =>
              changePackagePrice("package_price_nett", e.target.value)
            }
            disabled={isConfirmed}
          />
          <TextField
            type="number"
            value={payments?.package_price_gross}
            className={styles.package}
            fullWidth
            placeholder="Gross"
            onChange={(e) =>
              changePackagePrice("package_price_gross", e.target.value)
            }
            disabled={isConfirmed}
          />
        </div>
      </div>
      <div className={styles.reservations}>
        <div className={styles.box}>
          <p>Статус</p>
          <div className={styles.field}>
            <TSelect
              placeholder=""
              value={paymentTypesArray.find(
                (payment) => payment.key === status
              )}
              loadOptions={promiseOptions}
              defaultOptions={paymentTypesArray}
              components={{ IndicatorSeparator: null }}
              getOptionLabel={(opt) => opt.value}
              getOptionValue={(opt) => opt.key}
              onChange={onChangePaymentStatus}
              className={isConfirmed && "disabled"}
              styles={{
                control: () => ({
                  backgroundColor: "#fff",
                  border: "1px solid rgba(229, 233, 235, 1)",
                }),
                container: (styles) => ({
                  ...styles,
                  width: "100%",
                }),
              }}
            />
          </div>
        </div>
        {orderId && (
          <div className={styles.box}>
            <p>Linkt to confirmation</p>
            <div className={styles.field}>
              <Box
                display="flex"
                padding="10px"
                border="1px solid rgba(0,0,0,0.1)"
                borderRadius="8px"
                width="100%"
              >
                <Typography
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{FORM_LINK + orderId}</span>
                  <ContentCopyRoundedIcon
                    onClick={() => copyNavigator(orderId)}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Typography>
              </Box>
            </div>
          </div>
        )}

        {/* <div className={styles.box}>
          <p>Insurance</p>
          <div className={styles.field}>
            <Switch
              checked={with_isurance}
              name="with_isurance"
              onChange={onSwitch}
              disabled={isConfirmed}
            />
          </div>
        </div> */}
        <div className={styles.box}>
          <p>Transport</p>
          <div className={styles.field}>
            <Switch
              checked={with_transport}
              name="with_transport"
              onChange={onSwitch}
              disabled={isConfirmed}
            />
          </div>
        </div>
        {/* <div className={styles.box}>
          <p>Hotel</p>
          <div className={styles.field}>
            <Switch
              onChange={onSwitch}
              checked={with_hotel}
              name="with_hotel"
              disabled={isConfirmed}
            />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default PaymentDetails;
