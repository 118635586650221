import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Button, Divider, Popover, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { toast } from "react-hot-toast";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import styles from "./style.module.scss";
import useSessionAPI from "../../../../hooks/api/useSessionAPI";
import BlockIcon from "@mui/icons-material/Block";
import UnassignConfirmModal from "../UnassignConfirmModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const ActionRenderer = ({
  data,
  refetch,
  onOpenCreate,
  activeUsers,
  refetchActiveUsers,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUnassignOpen, setUnassignOpen] = useState(false);
  const [userId, setUserId] = useState(false);
  const { deleteSession } = useSessionAPI();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (id) => {
    toast.promise(
      deleteSession.mutateAsync(id, {
        onSuccess: refetchActiveUsers,
      }),
      {
        loading: "Deleting...",
        success: <b>Session is delete successful!</b>,
        error: <b>Error on deleting session.</b>,
      }
    );
  };

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  const open = Boolean(anchorEl);
  const id = open ? "session-simple-popover" : undefined;

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <MoreHorizIcon
        aria-describedby={id}
        onClick={handleClick}
        style={{
          cursor: "pointer",
        }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          width={120}
          display="flex"
          flexDirection="column"
          className={styles.popover_container}
        >
          {!activeUsers?.payload?.users?.some(
            (user) => user.username === data?.email
          ) ? (
            <Box className={styles.wrapper}>
              <Button
                variant="outlinedSecondary"
                onClick={() => onOpenCreate(data)}
                className={styles.button}
              >
                <LaunchRoundedIcon
                  style={{
                    fontSize: 16,
                  }}
                />
                Add
              </Button>
            </Box>
          ) : null}
          <Divider
            style={{
              width: "100%",
            }}
          />
          <Box className={styles.wrapper}>
            <Button
              className={styles.button}
              variant="outlinedSecondary"
              onClick={toggleModal}
            >
              <DeleteOutlineRoundedIcon
                style={{
                  fontSize: 16,
                }}
              />
              Delete
            </Button>
          </Box>
          <Divider
            style={{
              width: "100%",
            }}
          />
          <Box className={styles.wrapper}>
            <Button
              className={styles.button}
              variant="outlinedSecondary"
              onClick={() => {
                setUnassignOpen(true);
                setUserId(data?.id);
              }}
            >
              <BlockIcon
                style={{
                  fontSize: 16,
                }}
              />
              Unassign all
            </Button>
          </Box>
        </Box>
      </Popover>

      <Modal
        open={isModalOpen}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure , want to delete?
          </Typography>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            gap="10px"
            mt="15px"
          >
            <Button
              variant="outlinedSecondary"
              onClick={() => {
                handleDelete(
                  activeUsers?.payload?.users?.find(
                    (user) => user.username === data?.email
                  )?.id
                );
              }}
              style={{
                width: "50%",
              }}
            >
              Yes, delete
            </Button>
            <Button
              variant="containedSecondary"
              onClick={toggleModal}
              style={{
                width: "50%",
              }}
            >
              No, cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <UnassignConfirmModal
        open={isUnassignOpen}
        onClose={() => setUnassignOpen(false)}
        userId={userId}
        refetchActiveUsers={refetchActiveUsers}
      />
    </Box>
  );
};

export default ActionRenderer;
