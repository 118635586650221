import usePagination from "../../../hooks/usePagination";
import AddIcon from "@mui/icons-material/Add";
import TTable from "../../../components/Table";
import Wrapper from "../../../components/Wrapper";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import ActionRenderer from "./ActionRenderer";
import useAirlineAPI from "../../../hooks/api/useAirlineAPI";

const Airlines = () => {
  const navigate = useNavigate();
  const { page, amount, handleAmount, handlePaginate, filters } = usePagination(
    "/static-data/airlines"
  );

  const { data, isLoading, refetch } = useAirlineAPI({
    enabled: true,
    page: page,
    amount,
  });

  const _columns = [
    {
      label: "#",
      key: "index",
      attributes: {
        align: "left",
        width: 30,
      },
      render: ({ data }) => <>{data?.index}</>,
    },
    {
      label: "Title",
      key: "key",
      attributes: {
        align: "left",
      },
    },
    {
      label: "Value",
      key: "value",
      attributes: {
        align: "left",
        width: 100,
      },
    },
    {
      label: "Actions",
      key: "action",
      attributes: {
        align: "left",
        width: 50,
      },
      render: ({ data }) => <ActionRenderer data={data} refetch={refetch} />,
    },
  ];

  return (
    <Wrapper>
      <div>
        <TTable
          columns={_columns}
          page={Number(page)}
          amount={amount}
          setPage={handlePaginate}
          setAmount={handleAmount}
          isLoading={isLoading}
          total={data?.count}
          data={data?.rows?.map((airport, u) => ({
            ...airport,
            index: (Number(page) - 1) * Number(amount) + (u + 1),
          }))}
        >
          <Box display="flex" width="100%" justifyContent="space-between">
            <Button
              variant="outlinedSecondary"
              onClick={() => navigate("create")}
            >
              <AddIcon
                style={{ marginRight: "5px", color: "var(--primary-color)" }}
              />
              New Airline
            </Button>
          </Box>
        </TTable>
      </div>
    </Wrapper>
  );
};

export default Airlines;
